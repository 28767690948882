import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import Dropdown from '../dropdown';

const variants = {
  text: 'bg-transparent ',
  contained: 'border-[#FF9900] ',
  secondaryContained: 'border-[#f8c94633]',
  outlined: ' border-[#626669] ',
};

const TabNew = ({
  variant = 'contained',
  tabs,
  buttonClass = '',
  contentContainerClass = '',
  innerMainDivClassName = '',
  headerChildern,
}) => {
  const variantClass = variants[variant] || variants.contained;
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false); // State to manage menu visibility

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <div
        className={`flex justify-between items-center ${contentContainerClass}`}
      >
        <div className={innerMainDivClassName}>
          {/* Hamburger Menu */}
          <button className="block xl:hidden" onClick={handleMenuToggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>

          {/* Desktop Tabs */}
          <div className="hidden xl:flex">
            {tabs?.map((tab, index) => (
              <span key={index} className="xl:p-4 p-2 hover:bg-transparent">
                {tab?.nestedLink ? (
                  <Dropdown
                    title={
                      <button
                        className={`${
                          location?.pathname ===
                          (tab?.navLink ? `${tab?.navLink}` : `${tab?.value}`)
                            ? `border-[#FFCB05] rounded-sm border-b-2 ${variantClass}`
                            : ''
                        }  focus:outline-none text-sm xl:text-md hover:border-[#ffcd05a4] hover:rounded-sm hover:border-b-2 ${buttonClass}`}
                      >
                        {tab?.label}
                      </button>
                    }
                  >
                    {tab?.nestedLink?.map((nestLink) => (
                      <NavLink
                        key={nestLink.value}
                        to={{
                          pathname: nestLink?.navLink
                            ? `${nestLink?.navLink}`
                            : `${nestLink?.value}`,
                          search: location.search,
                        }}
                        onClick={handleCloseMenu}
                        className={({ isActive, isPending }) =>
                          isPending ? 'pending' : isActive ? 'active' : ''
                        }
                      >
                        {nestLink?.component}
                      </NavLink>
                    ))}
                  </Dropdown>
                ) : (
                  <NavLink
                    to={{
                      pathname: tab?.navLink
                        ? `${tab?.navLink}`
                        : `${tab?.value}`,
                      search: location.search,
                    }}
                    className={({ isActive, isPending }) =>
                      isPending ? 'pending' : isActive ? 'active' : ''
                    }
                  >
                    <button
                      className={`${
                        location?.pathname ===
                        (tab?.navLink ? `${tab?.navLink}` : `${tab?.value}`)
                          ? `border-[#FFCB05] rounded-sm border-b-2 ${variantClass}`
                          : ''
                      }  focus:outline-none text-sm xl:text-md hover:border-[#ffcd05a4] hover:rounded-sm hover:border-b-2 ${buttonClass}`}
                    >
                      {tab?.label}
                    </button>
                  </NavLink>
                )}
              </span>
            ))}
          </div>
        </div>
        <div>{headerChildern}</div>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-start items-start">
          <div className="w-full bg-black py-4 px-2">
            {/* Close button */}
            <button
              className="absolute top-0 z-[40] flex justify-end items-end w-52 right-0 m-4 text-white"
              onClick={handleCloseMenu}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {/* Mobile Menu Items */}
            <div className="mt-10 text-white">
              {tabs?.map((tab, index) => (
                <span
                  key={index}
                  className="block p-2 hover:bg-transparent border-b border-[#ffffff29]"
                >
                  {tab?.nestedLink ? (
                    <div>
                      {tab?.label}
                      <div className="pl-5">
                        {tab?.nestedLink?.map((nestLink) => (
                          <NavLink
                            key={nestLink.value}
                            to={{
                              pathname: nestLink?.navLink
                                ? `${nestLink?.navLink}`
                                : `${nestLink?.value}`,
                              search: location.search,
                            }}
                            onClick={handleCloseMenu}
                            className={({ isActive, isPending }) =>
                              isPending ? 'pending' : isActive ? 'active' : ''
                            }
                          >
                            {nestLink?.component}
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <NavLink
                      to={{
                        pathname: tab?.navLink
                          ? `${tab?.navLink}`
                          : `${tab?.value}`,
                        search: location.search,
                      }}
                      onClick={handleCloseMenu}
                      className={({ isActive, isPending }) =>
                        isPending ? 'pending' : isActive ? 'active' : ''
                      }
                    >
                      {tab.label}
                    </NavLink>
                  )}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

TabNew.propTypes = {
  variant: PropTypes.oneOf(['contained', 'secondaryContained', 'outlined']),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      component: PropTypes.node,
      nestedLink: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          navLink: PropTypes.string,
          component: PropTypes.node,
        })
      ),
    })
  ).isRequired,
  contentContainerClass: PropTypes.string,
  innerMainDivClassName: PropTypes.string,
  buttonClass: PropTypes.string,
  headerChildern: PropTypes.node,
};

export default TabNew;
