import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Carousel = ({
  images,
  customClassName,
  element = <></>,
  disableBackArrow = false,
  delay=3000
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, delay);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    console.log("nextImage");
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
console.log(images.length);
console.log("Current Index: ",currentIndex);
  return (
    <div className={`relative max-w-full w-full h-full ${customClassName}`} id='car'>
      {!disableBackArrow && images.length > 1 && currentIndex !== 0 && (
        <button
          className="absolute top-1/2 z-50 left-[50px] transform -translate-y-1/2 -translate-x-1/2 bg-gray-800 opacity-50 hover:opacity-100 rounded-full p-2"
          onClick={prevImage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="3"
            className=" h-7 w-7 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            ></path>
          </svg>
        </button>
      )}
      {images.length > 1 && (
        <button
          className="absolute top-1/2 z-50  right-[50px] transform -translate-y-1/2 translate-x-1/2 bg-gray-800 opacity-50 hover:opacity-100 rounded-full p-2"
          onClick={nextImage}
          id='next-image-handler'
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="3"
            className=" h-7 w-7 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            ></path>
          </svg>
        </button>
      )}
      {element}

      <div className="relative max-w-full w-full h-full">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={image.alt}
            className={`absolute top-0 -z-10- left-0 max-w-full w-full h-full bg-center object-cover ${index === currentIndex ? 'opacity-100' : 'opacity-0 transition-opacity duration-500'}`}
          />
        ))}
        {images.length > 1 && (
          <div className="absolute bottom-12 left-6 text-white">
            <div className="text-xs mb-3">
              {' '}
              {currentIndex + 1} / {images.length}
            </div>
            <div className=" flex space-x-1">
              {images.map((_, index) => (
                <>
                  <span
                    key={index}
                    className={`block h-[1px] cursor-pointer rounded-2xl transition-all content-['']  ${index === currentIndex ? 'w-24 bg-white/70' : 'w-11 bg-white/50'}  `}
                  ></span>
                </>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
  customClassName: PropTypes.string,
  disableBackArrow: PropTypes.bool,
  element: PropTypes.node,
  delay : PropTypes.number
};

export default Carousel;
