import { Outlet } from 'react-router-dom';
import Header from './modules/header/Header';
import Toaster from './uikit/toaster';
import { GlobalProvider } from './utils/contexts/GlobalContext';
import GlobalCode from './modules/globalcode';
import Footer from './modules/footer';

export default function Layout() {
  return (
    <>
      <div className="flex layout site-wrapper bg-[#F3F4F5] font-roboto-flex">
        <GlobalProvider>
          <main className="flex-1 flex-grow">
            <Header />
            <GlobalCode />
            <div className=" bg-[#F3F4F5]">
              <Outlet />
            </div>
            <Footer />
          </main>
        </GlobalProvider>
      </div>

      <Toaster />
    </>
  );
}
