import Carousel from '../../../../uikit/simple-carousel';

function Section1() {
  return (
    <div className="h-screen ">
      <Carousel
        images={[
          {
            src1: './images/landmarkProj.webp',
            src: './images/landmarkProj.webp',
            alt: 'Image 1',
          },
        ]}
        element={
          <>
            <div className="absolute left-4 xl:left-20 bottom-[20%] xl:bottom-[30%]  z-10 p-2">
              <div className="text-4xl text-white max-w-[720px]" >
                Landmark Projects Of Nakhsha
              </div>  
              <div className="border-l-2 px-2 py-1 border-[#FFCB05] ">
                <div className="text-[#FFCB05] text-xl mb-1">
                  Where Vision Meets Concrete Reality
                </div>
              </div>
            </div>
            <div className="absolute z-[2] top-0 left-0 w-full h-full bg-black opacity-25"></div>
          </>
        }
        disableBackArrow
      />
    </div>
  );
}

export default Section1;
