import PropTypes from 'prop-types';
import { createContext, useReducer, useContext } from 'react';

// Create a context
export const GlobalContext = createContext();

// Define your actions
export const SET_VALUE = 'SET_VALUE';

// Action creator
export function setValue(key, value) {
  return { type: SET_VALUE, key, value };
}

// Define your reducer
export const reducer = (state, action) => {
  switch (action.type) {
    case SET_VALUE:
      return { ...state, [action.key]: action.value };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

// Create a provider which holds the state
export function GlobalProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, {});

  // Wrap dispatch in a function to add error handling
  const safeDispatch = (...args) => {
    try {
      dispatch(...args);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <GlobalContext.Provider value={{ state, dispatch: safeDispatch }}>
      {children}
    </GlobalContext.Provider>
  );
}

GlobalProvider.propTypes = {
  children: PropTypes.any,
};

// Create a hook to use the context
export function useGlobal() {
  return useContext(GlobalContext);
}
