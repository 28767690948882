import Description from './Description';

function Section3() {
  return (
    <div className="min-h-[100%] ">
      <div>
        <Description />
      </div>
    </div>
  );
}

export default Section3;
