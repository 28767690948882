import EventImages from './images/P1100.jpg';
import BNI from './images/P2100.jpg';

function Description() {
  return (
    <div className="">
      <div className="">
        <div>
          <div className="flex md:justify-between justify-center items-center flex-col p-[20px]">
          <div className="md:w-full   ">
          <div className="md:text-2xl text-xl text-white mb-[60px] font-medium">
                {' '}
                We believe in the power of community and the spirit of
                sportsmanship. That&apos;s why we are proud sponsors of the
                prestigious BNI Better Together Cricket Tournament and the
                thrilling Xtrim Fight Night - Mixed Martial Arts Tournament.<br></br><br></br>  
                With a commitment to supporting local events that bring people
                together, we have proudly sponsored two editions of the BNI
                Better Together Tournament and two seasons of the Xtrim Fight
                Night. These sponsorships not only showcase our dedication to
                fostering community engagement but also reflect our passion for
                promoting health, fitness, and camaraderie.
                Partnering with these dynamic sporting events allows us to connect
            with our community on a deeper level while also demonstrating our
            ongoing commitment to supporting local talent and initiatives.
              </div>
              </div>
              <div className="flex lg:flex-row flex-col gap-[100px]">
              <img
                className="rounded md:w-[500px] w-[300px] object-cover "
                src={BNI}
                alt="relax"
              />
            <img
                className="rounded  md:w-[500px] w-[300px] object-cover "
                src={EventImages}
                alt="relax"
              />
              
            
         
              
            </div>
            
            
          </div>
         
        </div>
      </div>
    </div>
  );
}

export default Description;
