/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useState, useEffect } from 'react';
import { deepClone } from '../../utils/commonUtils';

const useInputChange = (validationRules = {}, initialState = {}) => {
  const [inputs, setInputs] = useState(initialState);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e, v = '') => {
    let name = '';
    let value = '';
    if (v?.toString().length) {
      name = e;
      value = v;
    } else {
      name = e?.target?.name || e;
      value = e?.target?.value || v;
    }

    value = typeof value === 'string' ? value?.trimStart() : value;

    const newErrors = { ...errors };
    if (newErrors[name] && value?.trim()) {
      delete newErrors[name]; // Remove "This field is required" error if content is entered
    }

    setErrors(newErrors);

    const isNumericOrTelInput = ['number', 'tel'].includes(e?.target?.type);

    if (isNumericOrTelInput && !/^\d*$/.test(value)) {
      // If the input is of type 'number' or 'tel' and the value is not numeric, do not update state
      return;
    }

    // Update inputs state with nested structure properly initialized
    setInputs((prevInputs) => {
      const updatedInputs = deepClone(prevInputs);
      const keys = name.split('.');
      let currentInput = updatedInputs;

      // Traverse through the keys to reach the nested structure
      keys.forEach((key, index) => {
        if (key.endsWith(']')) {
          // If the key indicates an array
          const arrayName = key.substring(0, key.indexOf('[')); // Extract array name
          const arrayIndex = parseInt(key.match(/\d+/)[0]); // Extract array index
          if (!currentInput[arrayName]) {
            currentInput[arrayName] = []; // Initialize array if not present
          }
          if (!currentInput[arrayName][arrayIndex]) {
            currentInput[arrayName][arrayIndex] = {}; // Initialize array element if not present
          }
          if (index === keys.length - 1) {
            // Last key, set the value
            currentInput[arrayName][arrayIndex] = value;
          }
          currentInput = currentInput[arrayName][arrayIndex]; // Move to the next level
        } else {
          if (!currentInput[key]) {
            currentInput[key] = {}; // Initialize object if not present
          }
          if (index === keys.length - 1) {
            // Last key, set the value
            currentInput[key] = value;
          }
          currentInput = currentInput[key]; // Move to the next level
        }
      });

      return updatedInputs;
    });
  };

  const hasValue = Object.keys(initialState).some((key) => initialState[key]);
  useEffect(() => {
    if (Object.keys(initialState).length > 0 && hasValue) {
      setInputs((prevInputs) => {
        const updatedInputs = { ...prevInputs };
        Object.keys(initialState).forEach((key) => {
          if (updatedInputs[key] === undefined) {
            updatedInputs[key] = initialState[key];
          }
        });
        return updatedInputs;
      });
    }
  }, [hasValue]);

  const validateSpecificInputs = async (
    fieldsToValidate = [],
    onSuccess = () => {}
  ) => {
    const newErrors = { ...errors };

    for (const inputName of fieldsToValidate) {
      const rules = validationRules?.[inputName];
      const value = inputs?.[inputName];

      if (value === undefined || value?.trim() === '') {
        if (!newErrors[inputName]) {
          newErrors[inputName] = [];
        }
        newErrors[inputName].push('This field is required.');
      } else if (rules) {
        for (const rule of rules) {
          if (rule.validator) {
            const validationResult = await rule.validator(value);
            if (!validationResult) {
              if (!newErrors[inputName]) {
                newErrors[inputName] = [];
              }
              newErrors[inputName].push(rule.message);
            }
          }
        }
      }
    }

    setErrors(newErrors);

    const isValid = Object.keys(newErrors).length === 0;

    if (isValid) {
      onSuccess(); // Call the success callback if inputs are valid
    }

    return isValid;
  };

  const addCustomError = (key, errorMessage) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [key]: [errorMessage], // Set the custom error message for the given key
    }));
  };

  const areInputsDirty = () => {
    const checkDirty = (current, initial) => {
      if (typeof current !== 'object' || current === null || initial === null) {
        return current !== initial;
      }

      const currentKeys = Object.keys(current);
      const initialKeys = Object.keys(initial);

      if (currentKeys.length !== initialKeys.length) {
        return true;
      }

      for (const key of currentKeys) {
        if (
          !initial.hasOwnProperty(key) ||
          checkDirty(current[key], initial[key])
        ) {
          return true;
        }
      }

      return false;
    };

    return checkDirty(inputs, initialState);
  };

  const getChangedFields = () => {
    const changedFields = {};

    const compareFields = (current, initial, path = '') => {
      for (const key in current) {
        const currentPath = path ? `${path}.${key}` : key;
        if (
          typeof current[key] === 'object' &&
          current[key] !== null &&
          initial[key] !== undefined &&
          initial[key] !== null
        ) {
          compareFields(current[key], initial[key] || {}, currentPath);
        } else if (current[key] !== initial[key]) {
          if (path === '') {
            // Handling non-nested fields without an empty string path
            changedFields[key] = current[key];
          } else {
            // Nested fields
            changedFields[path] = {
              ...changedFields[path],
              [key]: current[key],
            };
          }
        }
      }
    };

    compareFields(inputs, initialState);

    return changedFields;
  };

  const validateInputs = async () => {
    const newErrors = {};
    for (const inputName in validationRules) {
      const rules = validationRules?.[inputName];
      const value = inputs?.[inputName];
      for (const rule of rules) {
        if (rule.validator) {
          const validationResult = await rule.validator(value);
          if (!validationResult) {
            if (!newErrors[inputName]) {
              newErrors[inputName] = [];
            }
            newErrors[inputName].push(rule.message);
          }
        }
      }
      if (!newErrors[inputName]) {
        delete newErrors[inputName]; // Remove the field from errors if it's valid
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const areInputsValid = () => Object.keys(errors).length === 0;

  const handleBlur = async (e, v) => {
    let name = '';
    let value = '';
    if (v?.toString().length) {
      name = e;
      value = v;
    } else {
      name = e?.target?.name || e;
      value = e?.target?.value || v;
    }
    const { required } = e?.target;

    const newErrors = { ...errors };

    if (required && !value?.trim()) {
      newErrors[name] = ['This field is required.'];
    } else if (validationRules[name]) {
      delete newErrors[name]; // Remove the required error if the field has content
      for (const rule of validationRules[name]) {
        if (rule.validator) {
          const validationResult = await rule.validator(value);
          if (!validationResult) {
            newErrors[name] = [...(newErrors[name] || []), rule.message];
          }
        }
      }
    }

    setErrors(newErrors);
  };

  const clearInputsExcept = (fieldsToKeep = []) => {
    const clearedInputs = {};
    if (fieldsToKeep.length === 0) {
      setInputs({}); // Clear all inputs if empty array is passed
      return;
    }

    for (const key in inputs) {
      if (fieldsToKeep.includes(key)) {
        clearedInputs[key] = inputs[key];
      }
    }

    setInputs(clearedInputs);
  };

  const clearErrorsExcept = (fieldsToKeep = []) => {
    const clearedErrors = {};
    if (fieldsToKeep.length === 0) {
      setErrors({}); // Clear all errors if empty array is passed
      return;
    }

    for (const key in errors) {
      if (fieldsToKeep.includes(key)) {
        clearedErrors[key] = errors[key];
      }
    }

    setErrors(clearedErrors);
  };

  // const handleCustomInputChange = (name, value) => {
  // 	setInputs((prevInputs) => ({
  // 		...prevInputs,
  // 		[name]: value,
  // 	}));
  // };

  // Modify handleCustomInputChange function to handle nested structures properly
  const handleCustomInputChange = (name, value) => {
    setInputs((prevInputs) => {
      const updatedInputs = deepClone(prevInputs);
      const keys = name.split('.');
      let currentInput = updatedInputs;

      // Traverse through the keys to reach the nested structure
      keys.forEach((key, index) => {
        if (key.endsWith(']')) {
          // If the key indicates an array
          const arrayName = key.substring(0, key.indexOf('[')); // Extract array name
          const arrayIndex = parseInt(key.match(/\d+/)[0]); // Extract array index
          if (!currentInput[arrayName]) {
            currentInput[arrayName] = []; // Initialize array if not present
          }
          if (!currentInput[arrayName][arrayIndex]) {
            currentInput[arrayName][arrayIndex] = {}; // Initialize array element if not present
          }
          if (index === keys.length - 1) {
            // Last key, set the value
            currentInput[arrayName][arrayIndex] = value;
          }
          currentInput = currentInput[arrayName][arrayIndex]; // Move to the next level
        } else {
          if (!currentInput[key]) {
            currentInput[key] = {}; // Initialize object if not present
          }
          if (index === keys.length - 1) {
            // Last key, set the value
            currentInput[key] = value;
          }
          currentInput = currentInput[key]; // Move to the next level
        }
      });

      return updatedInputs;
    });
  };

  return {
    inputs,
    errors,
    handleInputChange,
    areInputsDirty,
    getChangedFields,
    validateInputs,
    validateSpecificInputs,
    areInputsValid,
    handleBlur,
    clearErrorsExcept,
    clearInputsExcept,
    handleCustomInputChange,
    addCustomError,
  };
};

export default useInputChange;

// USAGE

// import React from 'react';
// import useInputChange from './useInputChange';

// const MyForm = () => {
//   const initialState = {
//     name: '',
//     email: '',
//     password: '',
//   };

// const validationRules = {
//     name: [
//       {
//         validator: (value) => value.length > 0,
//         message: 'Name is required',
//       },
//       {
//         required: true,
//       },
//     ],
//     email: [
//       {
//         validator: (value) => value.length > 0,
//         message: 'Email is required',
//       },
//       {
//         validator: (value) =>
//           /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
//         message: 'Email is invalid',
//       },
//       {
//         required: true,
//       },
//     ],
//     password: [
//       {
//         validator: (value) => value.length > 0,
//         message: 'Password is required',
//       },
//       {
//         validator: (value) => value.length >= 8,
//         message: 'Password must be at least 8 characters long',
//       },
//       {
//         required: true,
//       },
//     ],
//   };

//   const { inputs, errors, handleInputChange, validateInputs } =
//     useInputChange(validationRules,initialState);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const isValid = await validateInputs();
//     if (isValid) {
//       // Submit the form
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label htmlFor="name">Name:</label>
//         <input
//           type="text"
//           id="name"
//           name="name"
//           value={inputs.name}
//           onChange={handleInputChange}
//         />
//         {errors.name && (
//           <ul>
//             {errors.name.map((error, index) => (
//               <li key={index}>{error}</li>
//             ))}
//           </ul>
//         )}
//       </div>
//       <div>
//         <label htmlFor="email">Email:</label>
//         <input
//           type="email"
//           id="email"
//           name="email"
//           value={inputs.email}
//           onChange={handleInputChange}
//         />
//         {errors.email && (
//           <ul>
//             {errors.email.map((error, index) => (
//               <li key={index}>{error}</li>
//             ))}
//           </ul>
//         )}
//       </div>
//       <div>
//         <label htmlFor="password">Password:</label>
//         <input
//           type="password"
//           id="password"
//           name="password"
//           value={inputs.password}
//           onChange={handleInputChange}
//         />
//         {errors.password && (
//           <ul>
//             {errors.password.map((error, index) =>
//               <li key={index}>{error}</li>
//             )}
//           </ul>
//         )}
//       </div>
//       <button type="submit">Submit</button>
//     </form>
//   );
// };

// export default MyForm;
