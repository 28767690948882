import { Suspense, lazy } from 'react';
import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
} from 'react-router-dom';

import Layout from '../Layout';
import SkeletonPage from '../modules/skeleton/SkeletonPage';
import Home from '../modules/home';
import Leadership from '../modules/leadership';
import Sponsorships from '../modules/sponsorships';
import Csr from '../modules/csr';
import Careers from '../modules/careers';
import Contact from '../modules/contact';
import PressMedia from '../modules/pressMedia';
import Homes from '../modules/homes';
import Builders from '../modules/builders';
import Collectives from '../modules/collectives';
import LandmarkProjects from '../modules/landmarkProjects';

const ErrorPage = lazy(() => import('../modules/error/ErrorPage'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
        <Route
          index
          element={
            <Suspense fallback={<SkeletonPage />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="leadership"
          element={
            <Suspense fallback={<SkeletonPage />}>
              <Leadership />
            </Suspense>
          }
        />
        <Route
          path="sponsorships"
          element={
            <Suspense fallback={<SkeletonPage />}>
              <Sponsorships />
            </Suspense>
          }
        />
        <Route
          path="csr"
          element={
            <Suspense fallback={<SkeletonPage />}>
              <Csr />
            </Suspense>
          }
        />
        <Route
          path="careers"
          element={
            <Suspense fallback={<SkeletonPage />}>
              <Careers />
            </Suspense>
          }
        />
        <Route
          path="landmark-projects"
          element={
            <Suspense fallback={<SkeletonPage />}>
              <LandmarkProjects />
            </Suspense>
          }
        />
        <Route
          path="press-media"
          element={
            <Suspense fallback={<SkeletonPage />}>
              <PressMedia />
            </Suspense>
          }
        />
        
        <Route
          path="contact"
          element={
            <Suspense fallback={<SkeletonPage />}>
              <Contact />
            </Suspense>
          }
        />
        <Route
          path="homes"
          element={
            <Suspense fallback={<SkeletonPage />}>
              <Homes />
            </Suspense>
          }
        />
        <Route
          path="builders"
          element={
            <Suspense fallback={<SkeletonPage />}>
              <Builders />
            </Suspense>
          }
        />
        <Route
          path="collectives"
          element={
            <Suspense fallback={<SkeletonPage />}>
              <Collectives />
            </Suspense>
          }
        />
        <Route path="*" element={<ErrorPage />} />
        
      </Route>
    </>
  )
);

export default router;
