import Carousel from '../../../../uikit/simple-carousel';
import Creation from './images/CREATION.png';

function Section1() {
  return (
    <>
    <div className="h-screen  max-md:hidden">
      <Carousel
        images={[
          {
            src: './images/builders.jpg',
            alt: 'Image 1',
          },
        
        ]}
        
        element={
          <>
            <>
              <div className="absolute w-full h-full flex justify-center items-start flex-col  z-20">
                <div className="flex justify-center  flex-col relative p-[50px]">
                
                  
                </div>
              </div>
             
            </>
          </>
        }
        disableBackArrow={false}
      />
    </div>
    <div className="h-screen  mobileview">
    <Carousel
      images={[
        {
          src: './images/Buildersbannerview.jpg',
          alt: 'Image 1',
        },
      
      ]}
      
      element={
        <>
          <>
            <div className="absolute w-full h-full flex justify-center items-start flex-col  z-20">
              <div className="flex justify-center  flex-col relative p-[50px]">
              
                
              </div>
            </div>
           
          </>
        </>
      }
      disableBackArrow={false}
    />
  </div>
  </>
  );
}

export default Section1;
