import { useState } from 'react';
import PropTypes from 'prop-types';

const Accordion = ({ defaultOpenIndex, items }) => {
  const [openIndex, setOpenIndex] = useState(defaultOpenIndex);

  const toggleAccordion = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="space-y-2">
      {items.map((item, index) => (
        <div className="relative  rounded" key={index}>
          <button
            className="w-full pt-2 pb-0 px-4 flex justify-between items-center focus:outline-none"
            onClick={() => toggleAccordion(index)}
          >
            <div className="flex justify-center items-center gap-2">
              <span className="text-sm mr-2">{`${index + 1}. `}</span>
              <span className="font-bold md:text-lg text-start">
                {item.title}
              </span>
            </div>

            <svg
              className={`transition-transform transform ${
                openIndex === index ? 'rotate-180' : 'rotate-0'
              }`}
              width="20"
              height="10"
              viewBox="0 0 22 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 1L11 11L1 1"
                stroke="#181817"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {openIndex === index && (
            <div className="p-2 ml-7 text-sm md:text-base">{item.content}</div>
          )}
        </div>
      ))}
    </div>
  );
};

Accordion.propTypes = {
  defaultOpenIndex: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};

Accordion.defaultProps = {
  defaultOpenIndex: null,
};

export default Accordion;
