import Description from './Description';

function Section3() {
  return (
    <div className="md:p-16 p-[20px] md:h-screen min:h-screen py-[40px]">
      <div className="h-full">
        <div className="h-full flex justify-center items-center">
          <Description />
        </div>
      </div>
    </div>
  );
}

export default Section3;
