import Carousel from '../../../../uikit/simple-carousel';

function Section1() {
  return (
    <>
    <div className="h-screen max-md:hidden">
      <Carousel
        images={[
          {
            
            src: './images/banner.png',
            alt: 'Image 1',
          },
        ]}
        element={
          <>
            <div className="absolute w-full h-full flex justify-center md:items-start  items-center flex-col  z-20">
              <div className="flex justify-start md:items-start  items-center  flex-col relative md:px-[80px] text-center md:text-left">
               <h1 className='md:text-5xl font-bold text-3xl'>
                Dream Home Construction<br>
                </br>
                Is Now Affordable

               
                </h1>
                <button className='bg-yellow-400 rounded p-2 font-bold'>Explore Now</button>
              </div>
            </div>
            
          </>
        }
        disableBackArrow
      />
    </div>
    <div className="h-screen mobileview">
    <Carousel
      images={[
        {
          
          src: './images/homesmobileview.jpg',
          alt: 'Image 1',
        },
      ]}
      element={
        <>
          <div className="absolute w-full h-full flex justify-center md:items-start  items-center flex-col  z-20">
            <div className="flex justify-start md:items-start  items-center  flex-col relative md:px-[80px] text-center md:text-left ">
             <h1 className='md:text-5xl font-bold text-2xl mt-[-250px]'>
              Dream Home Construction<br>
              </br>
              Is Now Affordable

             
              </h1>
              <button className='bg-yellow-400 rounded p-2 font-bold'>Explore Now</button>
            </div>
          </div>
          
        </>
      }
      disableBackArrow
    />
  </div>
  </>
  );
}

export default Section1;
