import ContinuousScroll from '../home/ContinuousScroll';
import Section5 from '../home/sections/section5';

function Contact() {
  return (
    <div className="min-h-screen bg-[#F4EAD1] pt-16 font-domine">
      <div className="mb-4 justify-center flex items-center">
        <Section5 />
      </div>
      <div className="bg-[#F4EAD1]  text-[#9F683B] py-1 border-t border-[#9F683B] mt-[-40px] max-w-full w-full">
        <ContinuousScroll text=" FIND OUT WHAT CAN WE DO FOR YOU / &nbsp;" />
      </div>
    </div>
  );
}

export default Contact;
