import Description from './Description';

function Section2() {
  return (
    <div className="min-h-full justify-start flex items-start flex-col p-5 bg-[url('modules/homes/sections/section2/images/Background.jpg')] bg-no-repeat bg-cover">
      <div className="w-full">
        <Description />
      </div>
    </div>
  );
}

export default Section2;
