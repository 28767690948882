// import Swiper core and required modules
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import { ArrowBackCircleOutline } from 'react-ionicons'
import { ArrowForwardCircleOutline } from 'react-ionicons'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-coverflow';
import first from './images/1.jpg';
import Second from './images/2.jpg';
import third from './images/3.jpg';
import Four from './images/4.jpg';
import Five from './images/5.jpg';
import Six from './images/h6100.png';
import Seven from './images/h7100.png';
export function Swp2() {
  return (

    <Swiper
      effect={'coverflow'}
      grabCursor={true}
      
      centeredSlides={true}
      loop={true}
      slidesPerView={'7'}
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 2.5,
        slideShadows: true,
        
        
      }}
      autoplay={true}
      pagination={{ el: '.swiper-pagination', clickable: true }}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        clickable: true,
      }}
      modules={[EffectCoverflow, Pagination, Navigation]}
      className="swiper_container"
    >
      <SwiperSlide>
        <img src={first} alt="slide_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={Second} alt="slide_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={third} alt="slide_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={Four} alt="slide_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={Five} alt="slide_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={Six} alt="slide_image" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={Seven} alt="slide_image" />
      </SwiperSlide>


      <div className="slider-controler">
        <div className="swiper-button-prev slider-arrow">
          
        </div>
        <div className="swiper-button-next slider-arrow">
          
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </Swiper>

  );
}
// export default Swp2;