import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function Dropdown({
  title,
  buttonClassName,
  dropdownClassName,
  orientation = 'bottom',
  children,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  function toggleDropdown() {
    setIsOpen(!isOpen);
  }

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={ref} className="relative inline-block text-left">
      {isOpen && (
        <div className="bg-black inset-0 fixed h-[500px] w-[100vw] -z-10"></div>
      )}

      <button
        type="button"
        className={`inline-flex justify-center w-full text-base font-medium  ${buttonClassName}`}
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={toggleDropdown}
      >
        {typeof title === 'string' ? <span>{title}</span> : title}
      </button>

      {isOpen && (
        <div
          className={`absolute z-20 rounded ${orientation === 'top' ? `bottom-0 right-0 mb-6` : orientation === 'top-right' ? `bottom-0 left-0 ` : `left-0 mt-2 origin-top-right`}  rounded-md shadow-lg w-max ring-1 ring-black ring-opacity-5 focus:outline-none ${dropdownClassName}`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="rounded" role="none" onClick={() => setIsOpen(false)}>
            {children}
          </div>
        </div>
      )}
    </div>
  );
}

Dropdown.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  buttonClassName: PropTypes.string,
  orientation: PropTypes.string,
  dropdownClassName: PropTypes.string,
};

export default Dropdown;
