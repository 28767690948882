import Description from './Description';

function Section2() {
  return (
    <div className="justify-center flex items-center flex-col">
      <div className="">
        <Description />
      </div>
    </div>
  );
}

export default Section2;
