import Empower from './images/empower.webp';
import First from './images/1100.jpg';
import Second from './images/2100.jpg';
import Third from './images/3100.jpg';
import Seven from './images/4100.jpg';
import Eight from './images/5100.jpg';
import Fourth from './images/1a100.jpg';
import Five from './images/2a100.jpg';
import Six from './images/3a100.jpg';
import Nine from './images/4a100.jpg';
import Ten from './images/5a100.jpg';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
const animation = { duration: 20000, easing: (t) => t }

 
function Description() {
  const [sliderRef] = useKeenSlider({
    loop:true,
    renderMode: "performance",
    created(s) {
      s.moveToIdx(5, true, animation)
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    breakpoints: {
      "(min-width: 300px)": {
        slides: { perView: 2, spacing: 5 },
      },
      "(min-width: 1000px)": {
        slides: { perView: 3, spacing: 20 },
      },
    },
   
  })
  const [sliderRef2] = useKeenSlider({
    loop:true,
    renderMode: "performance",
    created(s) {
      s.moveToIdx(-5, true, animation)
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs - 5, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs - 5, true, animation)
    },
    breakpoints: {
      "(min-width: 300px)": {
        slides: { perView: 2, spacing: 5 },
      },
      "(min-width: 1000px)": {
        slides: { perView: 3, spacing: 20 },
      },
    },
   
  })
  return (
    <div className="w-[100vw]">
      <div className="">
        <div className="bg-[url('./modules/csr/sections/section2/images/empowering.jpg')] bg-cover  w-[100vw] md:h-[100vh]">
          <div className="flex justify-start items-center h-full md:flex-row flex-col md:pl-[100px] p-[20px]">
            <div className="md:max-w-[40vw]  shrink-0">
              <div className="text-black md:text-4xl text-3xl font-bold" >Sathrang Event</div>
              <div className="text-black md:text-4xl text-3xl font-semibold " >
                Empowering Talented Youth
              </div>
              <div className="md:text-2xl text-xl text-[#1E1D1C] font-semibold">
                <div className='font-semibold'>Sathrang Once a Year Drawing & Painting Competition</div>
                <br></br>
                <div className="md:text-xl text-lg text-justify font-medium">
                  We believe in more than just building structures; we believe
                  in building communities and nurturing talent. That&apos;s why
                  we are proud to introduce Sathrang - Once a Year, an annual
                  Drawing & Painting competition held to celebrate
                  Children&apos;s Day. <br></br><br></br>What sets Sathrang Once a Year apart is
                  not just the opportunity it provides, but also the recognition
                  it offers. Winners of the competition are awarded with cash
                  prizes, acknowledging their talent and hard work.
                </div>
              </div>
            </div>
           

          </div>


        </div>
        <div className='bg-white  pt-[20px]'>
          <div className="text-[#181817] md:text-5xl text-2xl md:px-[100px] px-[20px]" style={{ fontFamily: 'Samarkan' }}>
            Sathrang Edition <span className="text-2xl md:text-4xl" style={{fontFamily:'Roboto'}}>1 - 2022</span>
          </div>
          <div className='px-[0px] mt-[50px] keen-slider' ref={sliderRef}>
            <img
              className="rounded  w-full md:h-[350px] h-[150px] flex keen-slider__slide number-slide1"
              src={First}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[350px] h-[150px] flex keen-slider__slide number-slide2"
              src={Second}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[350px] h-[150px] flex keen-slider__slide number-slide3"
              src={Third}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[350px] h-[150px] flex keen-slider__slide number-slide3"
              src={Seven}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[350px] h-[150px] flex keen-slider__slide number-slide3"
              src={Eight}
              alt="relax"

            />
          </div>
        </div>
        <div className='pt-[50px] bg-white  pb-[40px]'>
          <div className="text-[#181817] md:text-5xl text-2xl md:px-[100px] px-[20px]" style={{ fontFamily: 'Samarkan' }}>
            Sathrang Edition <span className="text-2xl md:text-4xl" style={{fontFamily:'Roboto'}}>2 - 2023</span>
          </div>
          <div className='px-[0px] mt-[50px] keen-slider' ref={sliderRef2}>
            <img
              className="rounded  w-full md:h-[350px] h-[150px] flex keen-slider__slide number-slide1"
              src={Fourth}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[350px] h-[150px] flex keen-slider__slide number-slide2"
              src={Five}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[350px] h-[150px] flex keen-slider__slide number-slide3"
              src={Six}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[350px] h-[150px] flex keen-slider__slide number-slide4"
              src={Nine}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[350px] h-[150px] flex keen-slider__slide number-slide5"
              src={Ten}
              alt="relax"

            />
          </div>
        </div>

      </div>
    </div>
  );
}

export default Description;
