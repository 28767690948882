export const isEmpty = (value) => {
  if (value === null || value === undefined) {
    return true;
  }
  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }
  return false;
};

export const deepClone = (obj, hash = new WeakMap()) => {
  if (
    Object(obj) !== obj ||
    obj instanceof Function ||
    obj instanceof RegExp ||
    obj instanceof Date
  ) {
    return obj; // Return primitives and non-cloneable types directly
  }

  if (hash.has(obj)) {
    return hash.get(obj); // Return the already cloned object
  }

  const clone = Array.isArray(obj)
    ? []
    : Object.create(Object.getPrototypeOf(obj));

  // Store the cloned object in the hash map to handle circular references
  hash.set(obj, clone);

  // Handle special cases like Date, RegExp, Map, Set, etc.
  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  if (obj instanceof RegExp) {
    const flags = obj.flags;
    return new RegExp(obj.source, flags);
  }

  if (obj instanceof Map) {
    obj.forEach((value, key) => {
      clone.set(deepClone(key, hash), deepClone(value, hash));
    });
    return clone;
  }

  if (obj instanceof Set) {
    obj.forEach((value) => {
      clone.add(deepClone(value, hash));
    });
    return clone;
  }

  // Clone objects and arrays recursively
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clone[key] = deepClone(obj[key], hash);
    }
  }

  return clone;
};

export const modifyKeys = (data, keyMap) => {
  if (!Array.isArray(data) || typeof keyMap !== 'object') {
    throw new Error(
      'Invalid input. Please provide an array of objects and a key mapping object.'
    );
  }

  return data?.map((item) => {
    if (typeof item !== 'object' || item === null) {
      throw new Error(
        'Invalid input. Each item in the array must be an object.'
      );
    }

    const newItem = {};

    for (const [oldKey, newKey] of Object.entries(keyMap)) {
      const keys = oldKey.split('.');
      let value = item;

      for (const k of keys) {
        if (value && value.hasOwnProperty(k)) {
          value = value[k];
        } else {
          value = undefined;
          break;
        }
      }

      newItem[newKey] = value;
    }

    Object.entries(item).forEach(([key, value]) => {
      if (!keyMap[key]) {
        newItem[key] = value;
      }
    });

    return newItem;
  });
};

// export const modifyKeys = (obj, keyMap) => {
//     // Usage
//     // const myObj = { a: 1, b: 2, c: 3 };
//     // const keyMap = { a: 'x', b: undefined };
//     // const updatedObj = modifyObject(myObj, keyMap);
//     // console.log(updatedObj); // { x: 1, c: 3 }
//     if (Array.isArray(obj)) {
//         const updatedObjArr = [];
//         for (const objElem of obj) {
//           updatedObjArr.push(modifyKeys(objElem, keyMap));
//         }
//         return updatedObjArr;
//       } else if (typeof obj === 'object' && obj !== null) {
//         if (typeof keyMap !== 'object' || keyMap === null) {
//           throw new Error('Invalid input: keyMap must be an object');
//         }

//         const updatedObj = {};
//         const updatedKeys = {};
//         for (const key in obj) {
//           if (obj.hasOwnProperty(key)) {
//             if (keyMap.hasOwnProperty(key)) {
//               const newKey = keyMap[key];
//               if (updatedKeys.hasOwnProperty(newKey)) {
//                 updatedObj[newKey] = obj[key];
//               } else {
//                 updatedObj[newKey] = obj[key];
//                 updatedKeys[newKey] = true;
//               }
//             } else {
//               updatedObj[key] = obj[key];
//             }
//           }
//         }
//         return updatedObj;
//       } else {
//         throw new Error('Invalid input: obj must be an object or an array of objects');
//       }
// }

export const addKeysToObject = (obj, keyMap) => {
  if (!obj) {
    return null;
  }
  if (Array.isArray(obj)) {
    const updatedObjArr = [];
    for (const objElem of obj) {
      updatedObjArr.push(addKeysToObject(objElem, keyMap));
    }
    return updatedObjArr;
  } else if (typeof obj === 'object' && obj !== null) {
    if (typeof keyMap !== 'object' || keyMap === null) {
      throw new Error('Invalid input: keyMap must be an object');
    }

    const updatedObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        updatedObj[key] = obj[key];
      }
    }
    for (const key in keyMap) {
      if (keyMap.hasOwnProperty(key)) {
        const value = String(obj[keyMap[key]]);
        updatedObj[key] = value;
      }
    }
    return updatedObj;
  } else {
    throw new Error(
      'Invalid input: obj must be an object or an array of objects'
    );
  }
};
