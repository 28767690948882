import {Swp2} from './Swiper';


function Description() {
  return (
    <>
    <div className=" w-[100%]">
      
     <h1 className="md:text-5xl text-2xl font-bold text-center">We Turns Dreams Into Reality And<br></br>Houses Into Homes</h1>
     
    </div>
    
    <div className='mt-[50px] '>
     <Swp2/>
     </div>
     <div className='mt-[50px] lg:mx-[50px] text-center md:text-2xl text-xl'>
     At NAKHSHA, we understand the significance of your residential space its not just a building its where memories are made, families grow, and lives unfold. With our expertise and dedication,were committed to crafting living spaces that reflect your lifestyle, values, and aspirations.

     </div>
    
    </>
  );
}

export default Description;
