import Description from './Description';
import Enhance4 from './images/enhance4.png';

function Section3() {
  return (
    <div className="p-0 min-h-screen relative">
      <div className="h-full">
        <div className="">
          <Description />
        </div>
      </div>
      
    </div>
  );
}

export default Section3;
