import Carousel from '../../../../uikit/simple-carousel';

function Section1() {
  return (
    <>
    <div className="h-screen max-md:hidden ">
      <Carousel
        images={[
          {
            src: './images/press.jpg',
            alt: 'Image 1',
          },
        ]}
        element={
          <>
            <div className="absolute left-4 xl:left-20 bottom-[20%] xl:bottom-[30%]  z-10 p-2">
              <div className="md:text-5xl text-3xl text-[#FFCB05] font-bold max-w-[720px]">
                Press & Media
              </div>
              <div className=" ">
                <div className="text-white md:text-2xl text-xl mb-1">
                  <div>Celebrating Our Construction</div>
                  <div>Company&apos;s Achievements</div>
                </div>
              </div>
            </div>
            <div className="absolute z-[2] top-0 left-0 w-full h-full bg-black opacity-25"></div>
          </>
        }
        disableBackArrow
      />
    </div>
    <div className="h-screen mobileview">
    <Carousel
      images={[
        {
          src: './images/Mobile View2.jpg',
          alt: 'Image 1',
        },
      ]}
      element={
        <>
          <div className="absolute left-4 xl:left-20 bottom-[20%] xl:bottom-[30%]  z-10 p-2">
            <div className="md:text-5xl text-3xl text-[#FFCB05] font-bold max-w-[720px]">
              Press & Media
            </div>
            <div className=" ">
              <div className="text-white md:text-2xl text-xl mb-1">
                <div>Celebrating Our Construction</div>
                <div>Company&apos;s Achievements</div>
              </div>
            </div>
          </div>
          <div className="absolute z-[2] top-0 left-0 w-full h-full bg-black opacity-25"></div>
        </>
      }
      disableBackArrow
    />
  </div>
  </>
  );
}

export default Section1;
