import Section from '../../uikit/section';
import Section1 from './sections/section1';
import Section2 from './sections/section2';
import Section3 from './sections/section3';
import Section7 from '../home/sections/section5';

const Collectives = () => {
  return (
    <div className="min-h-screen">
      <Section bgClass="bg-black">
        <Section1 />
      </Section>
      <Section bgClass="bg-white pb-12 ">
        <Section2 />
      </Section>
      <Section bgClass="bg-white" heightClass="h-full">
        <Section3 />
      </Section>
      <Section bgClass="bg-[#F4EAD1] justify-center flex items-center  md:min-h-screen py-[70px] "heightClass="h-full">
        <Section7 />{' '}
      </Section>
    </div>
  );
};

export default Collectives;
