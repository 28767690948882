import Carousel from '../../../../uikit/simple-carousel';

function Section1() {
  return (
    <div className="h-screen ">
      <Carousel
        images={[
          {
            src1: './images/careers.webp',
            src: './images/BC100.jpg',
            alt: 'Image 1',
          },
        ]}
        element={
          <>
            <div className="absolute left-4 xl:left-20 bottom-[20%] xl:bottom-[30%]  z-10 p-2">
              <div className="md:text-6xl text-4xl text-white max-w-[720px] font-bold">CAREERS</div>
              <div className=" ">
                <div className="text-white md:text-2xl text-xl mb-1">
                  Join the Fastest Growing Team in Mysore
                </div>
              </div>
            </div>
            <div className="absolute z-[2] top-0 left-0 w-full h-full bg-black opacity-25"></div>
          </>
        }
        disableBackArrow
      />
    </div>
  );
}

export default Section1;
