import PropTypes from 'prop-types';

const Card = ({ image, orientation, width, height, title, description }) => {
  return (
    <div className="bg-white shadow-md overflow-hidden">
      <img
        className={`w-full ${
          orientation === 'landscape' ? 'object-cover' : 'object-contain'
        }`}
        style={{ height: `${height}px` }} // Custom height
        src={image}
        alt={title}
      />
      <div className="p-4 text-right">
        <h2 className="text-xl font-semibold mb-2">{title}</h2>
        <p className="text-gray-700">{description}</p>
      </div>
    </div>
  );
};

Card.propTypes = {
  image: PropTypes.string.isRequired,
  orientation: PropTypes.oneOf(['portrait', 'landscape']),
  width: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

Card.defaultProps = {
  orientation: 'portrait',
  width: 'full',
  height: '400', // Default height
};

export default Card;
