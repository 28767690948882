import PropTypes from 'prop-types';

const Textarea = ({
  label,
  value,
  onChange,
  required,
  error,
  inputClassName,
  labelClassName,
  parentClassName,
  maxLength,
  expandable,
  showCounter,
  resize = false,
  placeholder,
  ...rest
}) => {
  return (
    <div className={`flex flex-col ${parentClassName}`}>
      {label ? (
        <label className={`text-gray-700 font-bold mb-2 ${labelClassName}`}>
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      ) : null}
      <textarea
        className={`border-b border-black p-2 bg-[#F4EAD1] ${inputClassName} ${error ? 'border-red-500' : ''} ${resize ? '' : 'resize-none'}`}
        value={value}
        onChange={onChange}
        required={required}
        maxLength={maxLength}
        placeholder={placeholder}
        {...rest}
      />
      {showCounter && (
        <p className="absolute bottom-0 right-0 mb-1 mr-1 text-base text-gray-500">
          {value.length}/{maxLength}
        </p>
      )}
      {expandable && (
        <p className="mt-1 text-base text-gray-500">
          {value.split('\n').length} lines
        </p>
      )}
      {error && <p className="mt-1 text-base text-red-500">{error}</p>}
    </div>
  );
};

Textarea.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  resize: PropTypes.bool,
  error: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  parentClassName: PropTypes.string,
  maxLength: PropTypes.number,
  expandable: PropTypes.bool,
  showCounter: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default Textarea;
