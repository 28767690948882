import Expertise from './images/Our Expertise.gif';
import check from './images/check.svg';

const EXPERTISE_POINTERS = [
  'Bar & Restaurants',
  'Event Spaces',
  'Resorts',
  'Clubs',
  'Hospitals',
  'Schools',
  'Colleges',
  'Office Spaces',
];
const EXPERTISE_POINTERS2 = [
  'Commercial Complexes',
  'PEB Structures',
  'Industrial Warehouses',
  'Supermarkets',
  'Shopping Malls',
  'Recreational Amenities',
];

function Description() {
  return (
    <div className="flex justify-center items-center flex-col bg-black pb-[50px]">
      <div className="text-center text-white md:text-5xl text-4xl md:p-12 p-10" style={{fontFamily:'IvyMode'}}>
        Our Expertise{' '}
      </div>
      <div className="flex md:gap-14 flex-col md:flex-row md:w-full h-full bg-black items-center justify-center">
        <div className="relative h-[200px] md:h-[100%] w-[100%] md:w-[70%]">
          {' '}
          <img
            src={Expertise}
            alt="Expertise"
            className="  w-full h-full "
          />{' '}
        </div>
        
       
      </div>
    </div>
  );
}

export default Description;
