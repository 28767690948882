import Description from './Description';
import Enhancer2 from './images/enhancer2.png';
function Section2() {
  return (
    <div className="w-full">
      
        <div className="  ">
          <Description />
        </div>
        
      
      
    </div>
  );
}

export default Section2;
