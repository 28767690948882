import VideoGallery from '../../../../uikit/videoGallery';

const ProjectsVideos = [
  {
    src: 'https://www.youtube.com/embed/9z8Q_ty9lbo?si=Go1dC0sTNHAKWZM4',
    title: 'Dakshina Paaka',
  },
  {
    src: 'https://www.youtube.com/embed/RSbVuchyOCQ?si=75BSvmVEOdzbzEWc',
    title: 'GRS Forging Unit',
  },
  {
    src: 'https://www.youtube.com/embed/hajUQ9trZvI?si=PV1QEYC-DqTBOQF6',
    title: 'Cosmopolitan club',
  },
  {
    src: 'https://www.youtube.com/embed/5OcEChHICTw?si=znhL-ybcNWmjTEec',
    title: 'Nakhsha Square',
  },
  {
    src: 'https://www.youtube.com/embed/IXrwNAsyhWY?si=VelMgnQh56W6G7E3',
    title: 'MICA',
  },
  {
    src: 'https://www.youtube.com/embed/0cG-bsOzaBk?si=CADysHhXTX8M01gU',
    title: 'Mysore Union',
  },
  {
    src: 'https://www.youtube.com/embed/nMiB0kGy4x4?si=W8rBHsgoJ_7GKKuP',
    title: 'Iconic Pub Mysore',
  },
  {
    src: 'https://www.youtube.com/embed/munSprxQ68I?si=Rlo7ltbnUvA9lPsq',
    title: 'GARODI STEELS',
  },
  {
    src: 'https://www.youtube.com/embed/wkAE-bg8avw?si=Lx-kJb_u5kEAy-m4',
    title: 'MICA College M.Com Block ',
  },
  {
    src: 'https://www.youtube.com/embed/STtXon0vcgk?si=oY_pbp5WJVVrhYX2',
    title: 'Nakhsha Furnishing studio',
  },
];

function Section6() {
  return (
    <div className="md:p-20 p-0 pt-0">
      <div>
        <div className="w-full text-center md:text-3xl text-2xl p-6 font-bold ">
          Completed Projects Building Tour
        </div>
        <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-content-center gap-5'>
          {
          ProjectsVideos.map((data) =>
            <div className='flex flex-col items-center justify-center gap-5' key={data.title}>
            <iframe className="sm:aspect-[6/3] md:aspect-video w-[100%] sm:h-[275px] md:h-[300px]"   src={data.src}  title={data.title} ></iframe>
           
            </div>
          )
          }
        </div>
      </div>
    </div>
  );
}

export default Section6;
