import Guarantee from './images/trustmarkers/guarantee.png';
import Awards from './images/trustmarkers/awards.png';
import Projects from './images/trustmarkers/1.png';
import Support from './images/trustmarkers/support.png';
import Home from './images/trustmarkers/2.png';
import Commercial from './images/trustmarkers/3.png';
import Drawer from './images/trustmarkers/4.png';
import Interior from './images/trustmarkers/5.png';
const trustMarkersData = [


  {
    image: Projects,
    title: 'Total Sqft Of Construction',
    subtitle: '10,00,000+',
  },
  {
    image: Home,
    title: 'Projects Delivered ',
    subtitle: '200+',

  },
  {
    image: Commercial,
    title: 'Residential Construction ',
    subtitle: '150+',
  },
  {
    image: Drawer,
    title: 'Commercial Construction ',
    subtitle: '20+',
  },
  {
    image: Interior,
    title: 'Interiors',
    subtitle: '30+',
  },

];


function TrustMarkers() {
  return (
    <>
      <div className="grid md:grid-cols-1 lg:grid-cols-6 px-[15px] pt-[50px] pb-[50px] max-md:gap-[30px]  justify-center items-center md:px-[90px] md:pb-[100px]">
        <div className="py-1 leading-tight">
          <div className="text-[#666666] md:text-5xl text-center text-2xl pb-1 lg:text-left font-bold w-full mb-[20px]">
            Overview Of
            What We Have
            Done So Far ??
          </div>
          
        </div>
        {trustMarkersData.map((marker, index) => (
          <div key={index} className="flex flex-col  items-center justify-center lg:border-r-2 lg:border-[#808080] h-[100%]">
            <div>
              <img
                src={marker.image}
                alt={marker.title}
                width="100"
                height="100"
                className="w-[75px] h-[75px]"
              />
            </div>
            <div className='border-r-[2px] border-black'>
              </div>
            <div className="py-1 leading-tight text-center w-[200px]">
              <div className="text-[#666666] md:text-3xl text-lg pb-1">
                {marker.title}
              </div>
              <div className="text-[#000000] md:text-3xl text-md">
                {marker.subtitle}
              </div>
            </div>
          </div>
        ))}

      </div>
     
    </>
  );
}

export default TrustMarkers;
