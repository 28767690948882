// Import table headers and content

const tableHeaders = ['YEAR', 'COMPETITION', 'AWARD', 'PROJECT'];

export const tableContent = [
  {
    year: '2022',
    competition: 'Mybuild Best Stall',
    award: 'GOLD PIN',
    project: 'Construction Expo',
  },
  {
    year: '2023',
    competition: 'Creative Stall',
    award: 'SILVER MEDAL',
    project: 'Building Showcase',
  },
  {
    year: '2024',
    competition: 'Naredco Awards',
    award: 'BRONZE TROPHY',
    project: 'Smart City Initiative',
  },
];

function AwardTable() {
  return (
    <table className="border-collapse table-auto w-full text-sm">
      <thead>
        <tr>
          {tableHeaders.map((header, index) => (
            <th key={index} className="font-medium  md:p-4 md:pl-8 pt-0 pb-3 md:text-2xl text-lg" style={{fontFamily:'IvyMode'}}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableContent.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {Object.values(row).map((value, colIndex) => (
              <td
                key={colIndex}
                className="md:p-4 md:pl-8 text-slate-500 dark:text-slate-400 text-md"
              >
                {value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default AwardTable;
