import VideoGallery from '../../../../uikit/videoGallery';

const ProjectsVideos = [
  {
    src: 'https://www.youtube.com/embed/STtXon0vcgk?si=c0UbTIUrvia54tvH',
    title: 'Nakhsha Furnishing studio',
  },
  {
    src: 'https://www.youtube.com/embed/IQb-ptjzjGs?si=d2rMvWxza2b6RfkU',
    title: 'ASPEN FLAT',
  },
  {
    src: 'https://www.youtube.com/embed/3CcZR0pDFtQ?si=z0QhTLrNWkrXTz6V',
    title: 'Stunning Transformation',
  },
  {
    src: 'https://www.youtube.com/embed/T-qceGJHtkI?si=_i44VF7mTSATb1Ct',
    title: 'A New Definition of a Home',
  },
  {
    src: 'https://www.youtube.com/embed/GLLUS39nLm0?si=qIuR46VHq3rm3Dik',
    title: 'Premium 40*50 Home',
  },
  {
    src: 'https://www.youtube.com/embed/Ezx_jWlx4zU?si=dUsQzP1y8p4bEf-N',
    title: 'Premium Renovation Project',
  },
  {
    src: 'https://www.youtube.com/embed/yAB0EQCYJm8?si=urEmUG8Zq4Gbto9y',
    title: 'Decoding the Architecture',
  },
  {
    src: 'https://www.youtube.com/embed/nFth20mnSJ4?si=53R8ELsNCZMnmdOp',
    title: 'One of the most premium ',
  },
  {
    src: 'https://www.youtube.com/embed/W2AxpSdw708?si=cmB3Z-2Zg4uAM_tY',
    title: 'Premium Interior Project',
  },
  {
    src: 'https://www.youtube.com/embed/STtXon0vcgk?si=oY_pbp5WJVVrhYX2',
    title: 'Nakhsha Furnishing studio',
  },
];

function Section3() {
  return (
    <div className="p-10 bg-[#F3F0EB]">
      <div>
      <div className="text-2xl md:text-5xl leading-none  font-bold  text-center my-[30px] mt-[0px]">
          <div>Our Portfolio</div>
          </div>
        <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-content-center gap-5 '>
          {
          ProjectsVideos.map((data) =>
            <div className='flex flex-col items-center justify-center gap-15' key={data.title}>
            <iframe className="sm:aspect-[6/3] md:aspect-video w-[100%] sm:h-[275px] md:h-[300px] " width="100%"  src={data.src}  title={data.title} ></iframe>

            </div>
          )
          }
        </div>
      </div>
    </div>
  );
}

export default Section3;
