import Work2 from './images/work2.webp';
import Work1 from './images/work1.webp';
import './Card.css';

function Progressive() {
	return (
		<div className="flex w-full bg-right h-full gap-3 md:bg-[url('modules/collectives/sections/section2/images/BC.jpg')] md:bg-cover bg-none bg-no-repeat items-center justify-center ">

			<div className=' md:py-[50px]  w-full'>
				<div className="text-xl md:text-4xl leading-none text-center relative z-10 font-bold max-md:hidden">
					<div className=" md:sw-[80%] ">Our Style Is Progressive And Our Influence Global.<br></br>
						We Create Brands, Interiors And Architecture</div>
					<div className=" md:w-[100%] font-normal md:text-xl text-lg  mt-[20px] leading-5 text-center">We believe that design is more than just aesthetics - its about creating experiences,<br></br> shaping environments, and telling stories.</div>

				</div>
				<div className="text-2xl md:text-4xl leading-none text-center relative z-10 font-bold mobileview px-6">
					<div className=" md:sw-[80%] leading-6">Our Style Is Progressive And <br></br> Our Influence Global.
						We Create Brands, Interiors And Architecture</div>
					<div className=" md:w-[100%] font-normal md:text-xl text-xl  mt-[20px]  text-center leading-6">We believe that design is more than just aesthetics - <br></br>its about creating experiences,<br></br> shaping environments, and telling stories.</div>

				</div>
				<div className="text-3xl md:text-5xl leading-none  font-bold  text-center mt-[50px]">
					<div>Our Expertise</div>
				</div>
				<div className="flex justify-center items-center flex-row gap-[30px] md:mt-[30px] w-full">

					<div className="container w-full max-w-[100%]">

						<div className="card bg-[url('modules/collectives/sections/section2/images/p1.png')] bg-cover bg-no-repeat">
							<div className='overlay max-md:hidden'>
								<div className="content ">

									<div className="contentBx">
										<h3>Architecture</h3>
									</div>
								</div>

								<ul className="sci ">
									<li>
										Architectural Planning and &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Design
									</li>
									<li>
										State-of-the-Art 3D Visualization
									</li>
									<li>
										Project Management
									</li>
								</ul>
								
							</div>
							<div className='overlay mobileview'>
								<div className="content ">

									<div className="contentBx">
										<h3>Architecture</h3>
									</div>
								</div>

								<ul className="sci ">
									<li>
										Architectural Planning and Design
									</li>
									<li>
										State-of-the-Art 3D Visualization
									</li>
									<li>
										Project Management
									</li>
								</ul>
								<ul className="sci ">
									<li>
										Architectural Planning and Design
									</li>
									<li>
										State-of-the-Art 3D Visualization
									</li>
									<li>
										Project Management
									</li>
								</ul>
							</div>
						</div>

						<div className="card bg-[url('modules/collectives/sections/section2/images/p2.png')] bg-cover bg-no-repeat">
							<div className='overlay'>
								<div className="content">

									<div className="contentBx">
										<h3>Interiors</h3>
									</div>
								</div>
								<ul className="sci">
									<li>
										Personalized Interior Design &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Services
									</li>
									<li>
										Luxury Renovation
									</li>
									<li>
										Re-modelling and Makeovers
									</li>

								</ul>
							</div>
						</div>
						<div className="card bg-[url('modules/collectives/sections/section2/images/p3.png')] bg-cover bg-no-repeat">
							<div className='overlay'>
								<div className="content">

									<div className="contentBx">
										<h3>Structural Engering</h3>
									</div>
								</div>
								<ul className="sci">
									<li>
										Planning
									</li>
									<li>
										Load Calculations
									</li>
									<li>
										Scientific Engineering
									</li>

								</ul>
							</div>
						</div>
						<div className="card bg-[url('modules/collectives/sections/section2/images/p4.png')] bg-cover bg-no-repeat">
							<div className='overlay'>
								<div className="content">

									<div className="contentBx">
										<h3>3D Visualisation</h3>
									</div>
								</div>
								<ul className="sci">
									<li>
										3d Walkthroughs
									</li>
									<li>
										High resul Renders
									</li>
									<li>
										AR \ VR
									</li>

								</ul>
							</div>
						</div>
						<div className="card bg-[url('modules/collectives/sections/section2/images/p5.png')] bg-cover bg-no-repeat">
							<div className='overlay'>
								<div className="content">

									<div className="contentBx">
										<h3>Furnishing</h3>
									</div>
								</div>
								<ul className="sci">
									<li>
										Home Décor
									</li>
									<li>
										Furniture
									</li>
									<li>
										Styling
									</li>

								</ul>
							</div>
						</div>
					</div>



				</div>
			</div>
		</div>
	);
}

export default Progressive;
