import Talent from './images/talent.png';
import First from '../section2/images/first.png';
import Second from '../section2/images/second.png';
import Carousel from '../../../../uikit/simple-carousel';

function Description() {
  return (
    <div className="flex justify-between md:flex-row flex-col h-full items-center  gap-[20px]">
      <div className="md:w-[40%]  ">
        <div className="text-3xl text-[#C8AE0B] mb-2 leading-9" style={{fontFamily:'IvyMode'}}>
          Join us in our journey to empower talented youth
        </div>
        <div className="text-lg text-white mt-[20px]">
          It&apos;s noteworthy that Sathrang Once a Year is a unique endeavor in
          the construction industry. While many companies focus solely on their
          projects, we understand the importance of giving back to society. By
          organizing this competition, we hope to inspire other companies to
          join us in our mission to support and uplift the next generation.
        </div>
      </div>
      <div className="w-[100%] md:h-[600px] h-[300px] ">
     
      <Carousel
      images={[
        {
          src: './images/talent.png',
          alt: 'Image 1',
        },
        {
          src: './images/first.JPG',
          alt: 'Image 1',
        },
        {
          src: './images/second.JPG',
          alt: 'Image 1',
        },
      ]}
      
        
        />
        
      </div>
    </div>
  );
}

export default Description;
