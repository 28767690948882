import Section from '../../uikit/section';
import ContinuousScroll from './ContinuousScroll';
import OnGoingProjects from './sections/onGoingProjects';
import Process from './sections/process';
import Section1 from './sections/section1';
import Section2 from './sections/section2';
import Section3 from './sections/section3';
import Section4 from './sections/section4';
import Section5 from './sections/section5';

const Home = () => {
  return (
    <div className="min-h-screen">
      <Section bgClass="bg-[#ffff]">
        <Section1 />{' '}
      </Section>
      <Section bgClass="bg-[#ffff]">
        <Section2 />{' '}
      </Section>
      
      {
      //   <Section bgClass="bg-white">
      //   <Process />
      // </Section>
      }
      {
      //   <Section bgClass="bg-[#141314]">
      //   <Section3 />{' '}
      // </Section>
      }
      <Section bgClass="bg-white min-h-screen">
        <Section4 />{' '}
      </Section>
      <Section bgClass="bg-[#F4EAD1] justify-center flex items-center min-h-full md:min-h-screen py-[70px] md:mt-0 mt-[-140px]" heightClass="h-full">
        <Section5 />{' '}
      </Section>
      <div className="bg-[#F4EAD1]  text-[#9F683B] py-5 border-t border-[#9F683B] max-w-full w-full min-h-full" >
        <ContinuousScroll text=" FIND OUT WHAT CAN WE DO FOR YOU / &nbsp;" />
      </div>
    </div>
  );
};

export default Home;
