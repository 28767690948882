import Work1 from './images/work1.png';
import Work2 from './images/work3.png';
import Work3 from './images/work2.png';
import Work4 from './images/work5.png';
import Work5 from './images/work4.png';
import Work6 from './images/work6.png';
import Work7 from './images/work8.png';
import Work8 from './images/work7.png';
import MasonryGrid from '../../../collectives/sections/section2/MasonryGrid';
import Card from '../../../collectives/sections/section2/Card';

const ProjectsVideos = [
  {
    src: 'https://www.youtube.com/embed/9z8Q_ty9lbo?si=Go1dC0sTNHAKWZM4',
    title: 'Dakshina Paaka',
  },
  {
    src: 'https://www.youtube.com/embed/RSbVuchyOCQ?si=75BSvmVEOdzbzEWc',
    title: 'GRS Forging Unit',
  },
  {
    src: 'https://www.youtube.com/embed/hajUQ9trZvI?si=PV1QEYC-DqTBOQF6',
    title: 'Cosmopolitan club',
  },
  {
    src: 'https://www.youtube.com/embed/5OcEChHICTw?si=znhL-ybcNWmjTEec',
    title: 'Nakhsha Square',
  },
  {
    src: 'https://www.youtube.com/embed/IXrwNAsyhWY?si=VelMgnQh56W6G7E3',
    title: 'MICA',
  },
  {
    src: 'https://www.youtube.com/embed/0cG-bsOzaBk?si=CADysHhXTX8M01gU',
    title: 'Mysore Union',
  },
  {
    src: 'https://www.youtube.com/embed/nMiB0kGy4x4?si=W8rBHsgoJ_7GKKuP',
    title: 'Iconic Pub Mysore',
  },
  {
    src: 'https://www.youtube.com/embed/munSprxQ68I?si=Rlo7ltbnUvA9lPsq',
    title: 'GARODI STEELS',
  },
  {
    src: 'https://www.youtube.com/embed/wkAE-bg8avw?si=Lx-kJb_u5kEAy-m4',
    title: 'MICA College M.Com Block ',
  },
  {
    src: 'https://www.youtube.com/embed/STtXon0vcgk?si=oY_pbp5WJVVrhYX2',
    title: 'Nakhsha Furnishing studio',
  },
];

function Works() {
  return (
    <div className="">
      <div className="text-2xl md:text-5xl text-center font-semibold py-8" style={{fontFamily:'IvyMode'}}>
        {' '}
        Landmark Projects <br /> of Nakhsha
      </div>
      {/* Mansonry Grid */}
      <div className="md:p-12 p-4 w-[70%] m-auto">
        <div className='flex flex-col md:flex-row md:gap-[50px] md:justify-start'>
        <div className='flex flex-col items-center gap-[10px]'>
        <img src={Work1}></img>
        <p className='font-semibold'>The Continental Inn & Suites</p>
        </div>
        <div className='flex flex-col items-center gap-[10px]'>
        <img src={Work2}></img>
        <p className='font-semibold'>MICA</p>
        </div>
        <div className='flex flex-col items-center gap-[10px]'>
        <img src={Work3}></img>
        <p className='font-semibold'>Dakshina Paaka</p>
        </div>
        </div>
        <div className='flex flex-col md:flex-row md:gap-[50px] md:justify-end md:my-[50px]'>
        <div className='flex flex-col items-center gap-[10px]'>
        <img src={Work4}></img>
        <p className='font-semibold'>GRS</p>
        </div>
        <div className='flex flex-col items-center gap-[10px]'>
        <img src={Work5}></img>
        <p className='font-semibold'>Unity Building</p>
        </div>
        <div className='flex flex-col items-center gap-[10px]'>
        <img src={Work6}></img>
        <p className='font-semibold'>GRS</p>
        </div>
        </div>
        <div className='flex flex-col md:flex-row md:gap-[50px] md:justify-start'>
        <div className='flex flex-col items-center gap-[10px]'>
        <img src={Work7}></img>
        <p className='font-semibold'>North Avenue</p>
        </div>
        <div className='flex flex-col items-center gap-[10px]'>
        <img src={Work8}></img>
        <p className='font-semibold'>North Avenue</p>
        </div>
        
        </div>
      </div>
      <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-content-center gap-10'>
          {
          ProjectsVideos.map((data) =>
            <div className='flex flex-col items-center justify-center gap-5' key={data.title}>
            <iframe className="sm:aspect-[6/3] md:aspect-video w-auto sm:h-[275px] md:h-[300px] " width="100%"  src={data.src}  title={data.title} ></iframe>
            <span>{data.title}</span>
            </div>
          )
          }
        </div>
      
    </div>
  );
}

export default Works;
