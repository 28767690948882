import EventImages from './images/eventImages.webp';
import Carousel from '../../../../uikit/simple-carousel';

function Description() {
  return (
    <>
    <div className=" md:h-[600px] h-[300px] w-full md:p-[100px] ">
    <div className="md:h-[500px] h-[300px] ">
   
   <Carousel
     images={[
       {
        
         src: './images/FG100.jpg',
         alt: 'Image 1',
       },
      
     ]}
     element={
       <>
         <div className="flex flex-col justify-center absolute h-full left-4 z-10 p-2">
           <div className="md:text-5xl text-2xl text-[#FFCB05] max-w-[720px] font-semibold ">
           Fight Night
           </div>
           <div className=" ">
             <div className="text-white text-lg md:max-w-[800px]">
             We believe in making a difference in the communities we serve. One of the ways we do this is through events that promote sports, art, culture, wellness & education
             </div>
           </div>
         </div>
         
       </>
     }
     
   />
 </div>
    </div>
    
    </>
  );
}

export default Description;
