import Expertise from './Expertise';
import Progressive from './Progressive';
import Works from './Works';

function Description() {
  return (
    <div>
      <Progressive />
      <Works />
      
    </div>
  );
}

export default Description;
