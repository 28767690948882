import Fb from './images/fb.png';
import Instagram from './images/instagram.png';
import Twitter from './images/x.png';
import Youtube from './images/youtube.png';

function Footer() {
  return (
    <footer className="bg-black text-white text-sm px-4 md:pl-16 pt-10 md:pt-24 pb-4">
      <div className="flex justify-between">
        <div className="grid lg:grid-cols-3 items-start justify-between place-content-between w-full max-md:gap-[50px]">
          
         <div className=''>
          <h1 className='text-4xl text-[#666666]'>Contact Us</h1>
          <div className="mt-3">
            
            <div className="flex justify-between">
              <div className="flex flex-col gap-[120px] md:flex-row md:gap-10  ">
               
                <div className="flex flex-col  justify-center p-2">
                  <div>
                    <div className="mb-4 max-w-[300px]">
                     <p className='font-bold text-[#B3B3B3] text-md'>
                     Email : <a className="underline" href="mailto:eo@nakhsha.in">eo@nakhsha.in </a><br></br><br></br>
                      Phone : <a className="underline" href="tel:+919606966299">+91 9606966299 (Commercial)</a><br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a className="underline" href="tel:+917022124411">+91 7022124411 (Residential)</a><br></br>
                      Address :  #5489,First Floor, 6th Main Rd, Vijay Nagar 2nd Stage,
                Vijayanagar, Mysuru, Karnataka 570017
                      </p>
                    </div>
                    
                  </div>
                </div>
              </div>
             
            </div>
          </div>
         </div>

          <div className="flex justify-start   items-start ">
            <div className="flex flex-col gap-6">
              <div className="text-4xl text-[#666666]">Follow us on:</div>
              <div className="text-[#CCCCCC] p-2">
                <div className="flex gap-2 md:gap-6 items-center">
                  <a href="https://www.facebook.com/constructioninmysore/">
                    <img src={Fb} className='w-[16px] h-[33px]' alt="facebook"></img>
                  </a>
                  
                  <a href="https://www.instagram.com/nakhsha_builders">
                    <img
                      src={Instagram}
                      className='w-[30px] h-[30px]'
                      alt="instagram"
                    ></img>
                  </a>
                  <a href="https://www.youtube.com/@nakhshabuilders4664">
                    <img
                      src={Youtube}
                      className='w-[40px] h-[30px]'
                      alt="youtube"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='p-2'>
          <p className='font-bold text-[#B3B3B3] text-md text-start'>
          Terms & Conditions <br></br> <br></br>
              Privacy Policy 
              </p>
          </div>
        </div>
      </div>
      <div className="text-md text-center text-[#717171] pt-10">
     © Copyright by Qurativity Media 
      </div>
    </footer>
  );
}

export default Footer;
