import Section from '../../uikit/section';
import Section1 from './sections/section1';
import Section2 from './sections/section2';
import Section5 from '../home/sections/section5';

const LandmarkProjects = () => {
  return (
    <div className="min-h-screen">
      <Section bgClass="bg-black">
        <Section1 />
      </Section>
      <Section bgClass="bg-white pb-12 ">
        <Section2 />
      </Section>
      <Section bgClass="bg-[#F4EAD1] justify-center flex items-center">
        <Section5 />{' '}
      </Section>
    </div>
  );
};

export default LandmarkProjects;
