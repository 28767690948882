import Description from './Description';
import Principles from './images/principles.webp';

function Section3() {
  return (
    <div className="">
      <div className="md:h-[40vh] h-[30vh]">
        <img src={Principles} className="w-full  h-full" alt="principles" />
      </div>
      <div className="py-20 px-4 md:pl-16">
        <div className="text-white max-w-[400px] text-4xl leading-none mb-20 ">
          Our Principles that define who we are
        </div>
        <div>
          <Description />
        </div>
      </div>
    </div>
  );
}

export default Section3;
