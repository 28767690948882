import Description from './Description';

function Section3() {
  return (
    <div className="my-0">
      <div>
        <Description />
      </div>
    </div>
  );
}

export default Section3;
