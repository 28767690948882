import Carousel from '../../../../uikit/simple-carousel';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
function Section1() {
  useEffect(() => {
    AOS.init({duration: 3000});
  }, [])
  return (
    <>
    <div className="h-screen max-md:hidden">
      <Carousel
        images={[
          {
            src1: './images/csr.webp',
            src: './images/final.gif',
            alt: 'Image 1',
          },
        ]}
        element={
          <>
            <div className="absolute left-10 xl:left-20 bottom-[40%]  z-10 p-2">
              <div className="md:text-[3vw] text-2xl text-black font-extrabold max-w-[720px] " data-aos="fade-left">
                CSR : Sathrang Event
              </div>
              
            </div>
            <div className=" absolute z-10 bottom-[8%] md:pl-[100px] pl-[20px]">
                <div className="text-[#FFCB05] text-2xl mb-1 font-semibold">
                  Empowering Talented Youth
                </div>
                <div className="text-white md:text-xl text-lg  md:max-w-[50vw]">
                  Sathrang Once a Year Drawing & Painting Competition We believe
                  in more than just building structures; we believe in building
                  communities and nurturing talent
                </div>
              </div>
           
          </>
        }
        disableBackArrow
      />
    </div>
    <div className="h-screen mobileview">
      <Carousel
        images={[
          {
            src1: './images/csr.webp',
            src: './images/csrmobileview.gif',
            alt: 'Image 1',
          },
        ]}
        element={
          <>
            <div className="absolute left-10 xl:left-20 bottom-[45%]  z-10 p-2">
              <div className="md:text-[3vw] text-2xl text-black font-extrabold max-w-[720px] " data-aos="fade-left">
                CSR : Sathrang Event
              </div>
              
            </div>
            <div className=" absolute z-10 bottom-[8%] md:pl-[100px] pl-[20px]">
                <div className="text-[#FFCB05] text-2xl mb-1 font-semibold">
                  Empowering Talented Youth
                </div>
                <div className="text-white md:text-xl text-lg  md:max-w-[50vw]">
                  Sathrang Once a Year Drawing & Painting Competition We believe
                  in more than just building structures; we believe in building
                  communities and nurturing talent
                </div>
              </div>
           
          </>
        }
        disableBackArrow
      />
    </div>
    </>
  );
}

export default Section1;
