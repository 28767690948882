import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const ContinuousScroll = ({ text }) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const textElement = textRef.current;

    // Calculate the number of copies needed to fill the container width
    const containerWidth = container.offsetWidth;
    const textWidth = textElement.offsetWidth;
    const copiesNeeded = Math.ceil(containerWidth / textWidth);

    // Clone and append text accordingly
    for (let i = 0; i < copiesNeeded; i++) {
      const clonedText = textElement.cloneNode(true);
      container.appendChild(clonedText);
    }

    function handleAnimationIteration() {
      if (textElement.getBoundingClientRect().right <= 0) {
        container.removeChild(textElement);
        const clonedText = textElement.cloneNode(true);
        container.appendChild(clonedText);
      }
    }

    textElement.addEventListener(
      'animationiteration',
      handleAnimationIteration
    );

    return () => {
      textElement.removeEventListener(
        'animationiteration',
        handleAnimationIteration
      );
    };
  }, [text]);

  return (
    <div
      className="scrolling-text-container max-w-full overflow-hidden whitespace-nowrap w-[98vw]"
      ref={containerRef}
    >
      <div className="scrolling-text" ref={textRef}>
        {text}
      </div>
    </div>
  );
};

ContinuousScroll.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ContinuousScroll;
