import ProcessImage from './images/process.webp';
function Process() {
  return (
    <div className=" max-w-full h-screen flex flex-col ">
      <div className="text-center">
        <div className="text-xl p-4 text-black">
          A seamless road to your dream home.
        </div>
      </div>
      <div className="flex justify-center  h-[80vh] relative ">
        <img
          src={ProcessImage}
          alt="process"
          className="absolute rounded top-0 left-0 max-w-full w-full h-full object-contain opacity-100"
        />
      </div>
    </div>
  );
}

export default Process;
