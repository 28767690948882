import Description from './Description';
import Carousel from '../../../../uikit/simple-carousel';
import One from './images/1.jpg';
import Two from './images/2.jpg';
import Three from './images/3.jpg';
import Four from './images/4.jpg';
import Five from './images/5.jpg';
import Six from './images/6.jpg';
const ProjectsVideos = [
  {
    // <iframe width="1920" height="919" src="https://www.youtube.com/embed/GLLUS39nLm0" title="Premium 40*50 Home | Nakhsha Builders Mysore | Dream Home Construction" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    src: 'https://www.youtube.com/embed/3yel7GzCdjc',
    
  },
  {
    src: 'https://www.youtube.com/embed/4A3aqPwR7W8',
   
  },
  {
    src: 'https://www.youtube.com/embed/SYcpGktgfu0 ',
    
  },
  {
    src: 'https://www.youtube.com/embed/egvIOQ0VdDQ',
    
  },
  {
    src: 'https://www.youtube.com/embed/wbnD3V9f3oI',
    
  },
  {
    src: 'https://www.youtube.com/embed/OQjlHyyhXD8',
    
  },
  
];
function Section3() {
  return (
    <>
    
    <div className=" flex items-center justify-center  md:h-[800px] h-[400px] w-full" >
      
          <Description />
        
    </div>
    
    <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-content-center gap-5 pb-[100px] md:px-[100px] px-[30px]'>
    {
    ProjectsVideos.map((data) =>
      <div className='flex flex-col items-center justify-center gap-5' key={data.src}>
      <iframe className="sm:aspect-[6/3] md:aspect-video w-[100%] sm:h-[275px] md:h-[300px]   "   src={data.src}  title={data.src} ></iframe>
      
      </div>
    )
    }
  </div>
    <div className="md:h-[500px] h-[300px] md:px-[100px]">
   
      <Carousel
        images={[
          {
           
            src: './images/bni.png',
            alt: 'Image 1',
          },
         
        ]}
        element={
          <>
            <div className="flex flex-col justify-center absolute h-full left-4 xl:left-20  z-10 p-2">
              <div className="md:text-5xl text-2xl text-[#FFCB05] font-semibold ">
              BNI Better Together Cricket Tournament
              </div>
              <div className="  ">
                <div className="text-white text-xl md:max-w-[800px]">
                We believe in making a difference in the communities we serve. One of the ways we do this is through events that promote sports, art, culture, wellness & education
                </div>
              </div>
            </div>
            
          </>
        }
        
      />
    </div>
    <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-content-center gap-10 pb-[100px] mt-[50px] md:px-[100px] px-[30px]'>
          <img src={One} className='w-full h-full'></img>
          <img src={Two} className='w-full h-full'></img>
          <img src={Three} className='w-full h-full'></img>
          <img src={Four} className='w-full h-full'></img>
          <img src={Five} className='w-full h-full'></img>
          <img src={Six} className='w-full h-full'></img>

    </div>
   
    </>
  );
}

export default Section3;
