import Section from '../../uikit/section';
import Section1 from './sections/section1';
import Section2 from './sections/section2';
import Section3 from './sections/section3';
import Section4 from './sections/section4';

import Section6 from './sections/section6';
import Section7 from '../home/sections/section5';

const Builders = () => {
  return (
    <div className="">
      <Section bgClass="bg-black">
        <Section1 />{' '}
      </Section>
      <Section bgClass="bg-white" heightClass="h-full">
        <Section2 />{' '}
      </Section>
      <Section bgClass="bg-white min-h-[100%]"heightClass="h-full">
        <Section3 />{' '}
      </Section>
     { 
    //  <Section bgClass="bg-white">
    //     <Section4 />{' '}
    //   </Section>

      // <Section bgClass="bg-black">
      //   <Section5 />{' '}
      // </Section>
}
      <Section bgClass="bg-white" heightClass="h-full">
        <Section6 />{' '}
      </Section>
      <Section bgClass="bg-[#F4EAD1] justify-center flex items-center min-h-full md:min-h-screen py-[70px] md:mt-0 mt-[30px]" heightClass="h-full" >
        <Section7 />{' '}
      </Section>
    </div>
  );
};

export default Builders;
