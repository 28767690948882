import Input from '../../../../uikit/input';
import Projects from './images/projects.webp';
import useInputChange from '../../../../utils/hooks/useInputChange';
import Textarea from '../../../../uikit/textarea';
import axios from 'axios';

function Section5() {
  const { inputs, errors, handleInputChange, handleBlur } = useInputChange();
  
  const submitForm = async (e) => {
    e.preventDefault();
   
      

    // create a new XMLHttpRequest
  await axios.post('https://nakhsha.in/mail.php?name='+inputs.name+'&email='+inputs.email+'&message='+inputs.message);
   

    // reset the fields
    this.setState({
        name: '',
        email: '',
        message: ''
    });
    alert("Form Submitted Successfully");
    window.location.href="https://nakhsha.in";
    
}
  return (
    <>
      <div className="md:p-10 p-4 w-[80%] py-0 " id="contact">
        <div>
          <div className="flex flex-col ">
            <div className="text-2xl md:text-6xl leading-none max-w-[400px] pb-12  text-[#666666]">
              We&apos;d love to Hear From You
            </div>
            <form >
            <div className="w-full h-full flex flex-col gap-10px ">
              <div className="w-full">
                <div className="flex w-full md:flex-row flex-col  md:mb-[30px]">
                  <div className="w-full md:w-[50%]">
                    <Input
                      type="text"
                      name="name"
                      required
                      value={inputs?.name || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Name"
                      error={errors?.name?.[0]}
                      parentClassName={'w-full'}
                      className='border py-[15px] pl-[20px] bg-[#F2F2F2] text-[#666666] rounded-sm'
                      errorClassName={'backdrop-blur-3xl px-4 py-1 rounded-3xl'}
                    />
                  </div>
                  <div className="w-full md:w-[50%] md:ml-4">
                    <Input
                      type="email"
                      name="email"
                      required
                      value={inputs?.email || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Email"
                      error={errors?.email?.[0]}
                      className='border py-[15px] pl-[20px] bg-[#F2F2F2] text-[#666666]'
                      errorClassName={'backdrop-blur-3xl px-4 py-1 rounded-3xl'}
                    />
                  </div>
                </div>
                <div className="flex w-full md:flex-row flex-col">
                  <div className="w-full md:w-[50%]">
                    <Input
                      type="text"
                      name="Phone"
                      required
                      value={inputs?.Phone || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Phone No"
                      error={errors?.Phone?.[0]}
                      parentClassName={'w-full'}
                      className='border py-[15px] pl-[20px] bg-[#F2F2F2] text-[#666666]'
                      errorClassName={'backdrop-blur-3xl px-4 py-1 rounded-3xl'}
                    />
                  </div>
                  <div className="w-full md:w-[50%] md:ml-4">
                    <Input
                      type="text"
                      name="Subject"
                      required
                      value={inputs?.Subject || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Subject"
                      error={errors?.Subject?.[0]}
                      className='border py-[15px] pl-[20px] bg-[#F2F2F2] text-[#666666]'
                      errorClassName={'backdrop-blur-3xl px-4 py-1 rounded-3xl'}
                    />
                  </div>
                </div>
                <div className="md:mt-5 flex">
                  <Textarea
                    type="text"
                    name="message"
                    required
                    value={inputs?.message || ''}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    placeholder="Message"
                    error={errors?.message?.[0]}
                    parentClassName={'w-full'}
                    className='border py-[15px] pl-[20px] bg-[#F2F2F2] text-[#666666]'
                    errorClassName={'backdrop-blur-3xl px-4 py-1 rounded-3xl'}
                  />
                  
                </div>
                <button className="bg-[#FFC906] w-[120px] h-[30px] text-[#00000] mt-[30px] rounded-md " onClick={submitForm}>
                    Submit
                  </button>
              </div>
            </div>
            </form>
            
          </div>
         
        </div>
      </div>
    </>
  );
}

export default Section5;
