import VideoGallery from '../../../../uikit/videoGallery';
import ProcessImage from './images/process.gif';

const ProjectsVideos = [
  {
    // <iframe width="1920" height="919" src="https://www.youtube.com/embed/GLLUS39nLm0" title="Premium 40*50 Home | Nakhsha Builders Mysore | Dream Home Construction" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    src: 'https://www.youtube.com/embed/KU_2qS749X0',
    title: 'Premium Kitchen  ',
  },
  {
    src: 'https://www.youtube.com/embed/1rGJ_pvuy5U ',
    title: 'Dream Living Space',
  },
  {
    src: 'https://www.youtube.com/embed/JRZLwqOGOJo',
    title: 'A New Definition of a Home',
  },
  {
    src: 'https://www.youtube.com/embed/bCef1c3EmhA',
    title: 'Premium 40*60',
  },
  {
    src: 'https://www.youtube.com/embed/T-qceGJHtkI',
    title: 'Dream Home Construction',
  },
  {
    src: 'https://www.youtube.com/embed/GLLUS39nLm0',
    title: '50 x 80 Site G+1',
  },
  {
    src: 'https://www.youtube.com/embed/pgxlA85fios',
    title: 'DHARMASH 30x40',
  },
  {
    src: 'https://www.youtube.com/embed/tkYkZ2LIXKI',
    title: '20 X 30 Independent Home',
  },
  {
    src: 'https://www.youtube.com/embed/MbWHGRpKTUA',
    title: '20 X 30 Independent Home',
  },
  {
    src: 'https://www.youtube.com/embed/NzxdAwbfSio',
    title: '20 X 30 Independent Home',
  },
  {
    src: 'https://www.youtube.com/embed/BbR_ZuvsV3U',
    title: '20 X 30 Independent Home',
  },
  {
    src: 'https://www.youtube.com/embed/LzSEamAchhA',
    title: '20 X 30 Independent Home',
  },
  {
    src: 'https://www.youtube.com/embed/Z5Bj0ipiFks',
    title: '20 X 30 Independent Home',
  },
  {
    src: 'https://www.youtube.com/embed/gzioAcS8s2g',
    title: '20 X 30 Independent Home',
  },
];

const TestimonialsVideos = [
  {
    src: 'https://www.youtube.com/embed/OHY_8pn7kFM',
    title: ' Mr & Mrs Sudheer R',
  },
  {
    src: 'https://www.youtube.com/embed/I5uOApe7daA',
    title: 'Midhun Raj',
  },
  {
    src: 'https://www.youtube.com/embed/szEhZQueD14',
    title: 'Mysore Lohith',
  },
  {
    src: 'https://www.youtube.com/embed/7qwVYj6DhIE',
    title: 'Mysore Suhas',
  },
  {
    src: 'https://www.youtube.com/embed/Dgl8Ijo92Wo',
    title: 'Mysore Vijaykumar',
  },
  {
    src: 'https://www.youtube.com/embed/7MK_JhN4VzM',
    title: 'Anil & Ashwini',
  },
  {
    src: 'https://www.youtube.com/embed/oOA6s8NRKo8',
    title: 'Dhruthi & Tej',
  },
  {
    src: 'https://www.youtube.com/embed/uxeJJ2tOcmc',
    title: 'Dhruthi & Tej',
  },
  {
    src: 'https://www.youtube.com/embed/1QYnHOff_IY',
    title: 'Dhruthi & Tej',
  },
];

function Section4() {
  return (
    <div className='py-[50px] '>
      <div className="w-full pb-[40px] flex justify-center md:items-start  items-center flex-col  z-20">
     
        <img
          src={ProcessImage}
          alt="process"
          className=" rounded top-0 left-0 w-full h-full object-cover "
        />
      </div>
      <div className='px-[30px]'>
        <div className="w-full text-center md:text-4xl text-xl md:py-6 p-2 font-thin " style={{fontFamily:'Gotham'}}>
          Completed Projects Home Tour
        </div>
        <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-content-center gap-5'>
          {
          ProjectsVideos.map((data) =>
            <div className='flex flex-col items-center justify-center gap-5' key={data.title}>
            <iframe className="sm:aspect-[6/3] md:aspect-video w-[100%] sm:h-[275px] md:h-[300px]" width="100%"  src={data.src}  title={data.title} ></iframe>
           
            </div>
          )
          }
        </div>
      </div>
      <div className='px-[30px]'>
        <div className="w-full text-center md:text-4xl text-xl md:py-6 p-4 pt-6 font-thin "  style={{fontFamily:'Gotham'}}>
          Client Testimonials
        </div>
        <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-content-center gap-5'>
        {
          TestimonialsVideos.map((data) =>
            <div className='flex flex-col items-center justify-center gap-5' key={data.title}>
            <iframe className="sm:aspect-[6/3] md:aspect-video w-[100%] sm:h-[275px] md:h-[300px] "   src={data.src}  title={data.title} ></iframe>
            
            </div>
          )
          }
          </div>
      </div>
    </div>
  );
}

export default Section4;
