import Description from './Description';

function Section2() {
  return (
    <div className="min-h-screen">
      <div className="">
        <Description />
      </div>
    </div>
  );
}

export default Section2;
