import logo from './images/3.png';
import { NavLink, useLocation } from 'react-router-dom';
// import { useGlobal } from '../../utils/contexts/GlobalContext';
import TabNew from '../../uikit/tab_new';
import { HashLink } from 'react-router-hash-link';

function Header() {
  // const { state } = useGlobal();
  const location = useLocation();
  return (
    <header className="absolute top-0 z-40 flex items-center justify-center w-full px-2 py-4  duration-500  max-xs:py-2 md:px-6 bg-black bg-opacity-25 ">

      <div className="flex items-center  justify-between w-full">
        <div className="flex items-center h-9 gap-x-4 max-xs:gap-x-2">
          <NavLink to={`/`}>
            <img alt="logo" width={50} height={50} src={logo} />
          </NavLink>
        </div>
        <div
          className={`${location.pathname === '/homes' || location.pathname === '/contact' ? 'text-white' : 'text-white'}`}
        >

          <TabNew
            variant="text"
            tabs={[
              {
                label: 'Business',
                nestedLink: [
                  {
                    navLink: 'homes',
                    component: (
                      <div className="bg-black w-full py-1 md:py-6 px-0">
                        <div className="text-sm md:text-xl text-[#F7DD7A]">
                          NAKHSHA HOMES
                        </div>
                        <hr className="border-b md:border-[#848485] border-[#ffffff29] my-1 md:my-2"></hr>
                        <div className="text-xs md:text-base">
                          Turnkey Residential Construction.... Explore More{' '}
                        </div>
                      </div>
                    ),
                  },
                  {
                    navLink: 'builders',
                    component: (
                      <div className="bg-black w-full py-1 md:py-6 px-0">
                        <div className="text-sm md:text-xl text-[#F7DD7A]">
                          NAKHSHA BUILDERS
                        </div>
                        <hr className="border-b md:border-[#848485] border-[#ffffff29] my-1 md:my-2"></hr>
                        <div className="text-xs md:text-base">
                          Turnkey Commercial Construction.... Explore More
                        </div>
                      </div>
                    ),
                  },
                  {
                    navLink: 'collectives',
                    component: (
                      <div className="bg-black w-full py-1 md:py-6 px-0">
                        <div className="text-sm md:text-xl text-[#F7DD7A]">
                          NAKHSHA COLLECTIVES
                        </div>
                        <hr className="border-b md:border-[#848485] border-[#ffffff29] my-1 md:my-2"></hr>
                        <div className="text-xs md:text-base">
                          Architectural Designs & Services.... Explore More{' '}
                        </div>
                      </div>
                    ),
                  },
                ],
              },
              { label: 'Leadership', navLink: '/leadership' },
              { label: 'Sponsorships', navLink: '/sponsorships' },
              { label: 'CSR', navLink: '/csr' },
              { label: 'Careers', navLink: '/careers' },
              { label: 'Press Media & Awards', navLink: '/press-media' },
              

            ]}
          />
        </div>
      </div>
    </header>
  );
}

export default Header;
