import Description from './Description';
import Frame from './images/frame.png';
function Section2() {
  return (
    <>
      <div className="md:p-0 p-0 min-h-screen relative">
        <div className="h-full">
          <div className="h-full flex justify-center items-center">
            <Description />
          </div>
        </div>
      </div>
      <div className=' pt-[50px] pb-[50px] bg-white' >

        <div className='relative'>

          <h1 className='md:text-5xl text-3xl z-10 text-center'><span className='font-bold' >Join our team of
            future-makers </span></h1>


        </div>

        <div className='md:px-[100px] px-[30px]'>
          <div className='relative md:text-4xl text-2xl font-bold mt-[30px]'>
            <p>Open Positons</p>


          </div>
          <hr className='border-solid border-black'></hr>
          <div className='mt-[20px]'>
            <ol className='flex flex-col gap-5 font-semibold text-2xl'>
              <li>Civil Project Manager</li>
              <li>Interior Designer</li>
              <li>Civil Architect</li>
              <li>3D Visualizer</li>
              <li>Interiors Plan Manager</li>
            </ol>
          </div>
        </div>

      </div>


    </>
  );
}

export default Section2;
