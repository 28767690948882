import Carousel from '../../../../uikit/simple-carousel';
import one from './images/1.png';
import two from './images/2.png';
import three  from './images/3.png';

function Section1() {
  return (
    <>
    <div className="h-screen max-md:hidden">
      <Carousel
        images={[
          {
           
            src: './images/extremeSports.webp',
            alt: 'Image 1',
          },
          {
           
            src: './images/bni.jpg',
            alt: 'Image 2',
          },
        ]}
        element={
          <>
          <div className='hidden lg:flex md:flex-row  gap-[40px] align-center justify-center pt-[110px]  absolute z-10 w-full  lg:visible'>
            
              <img src={one} className='w-[300px]'></img>
              <img src={two} className='w-[300px]'></img>
              <img src={three} className='w-[300px]'></img>
              
          </div>
            <div className="absolute left-4 xl:left-20 bottom-[20%]  z-10 p-2 ">
              <div className="md:text-4xl text-2xl font-extrabold max-w-[720px] text-[#FFCB05] mt-[50px]">
                Sponsorships
              </div>
              <div className="">
                <div className="text-white md:text-2xl text-xl md:max-w-[40vw]">
                  We believe in making a difference in the communities we serve.
                  One of the ways we do this is through events that promote
                  sports, art, culture, wellness & education
                </div>
              </div>
            </div>
            
          </>
        }
        
      />
    </div>
     <div className="h-screen mobileview">
     <Carousel
       images={[
         {
          
           src: './images/BC1100.jpg',
           alt: 'Image 1',
         },
         {
          
           src: './images/BC2100.jpg',
           alt: 'Image 2',
         },
       ]}
       element={
         <>
         <div className='hidden lg:flex md:flex-row  gap-[40px] align-center justify-center pt-[110px]  absolute z-10 w-full  lg:visible'>
           
             <img src={one} className='w-[300px]'></img>
             <img src={two} className='w-[300px]'></img>
             <img src={three} className='w-[300px]'></img>
         </div>
           <div className="absolute left-4 xl:left-20 bottom-[15%]  z-10 p-2 ">
             <div className="md:text-4xl text-2xl font-extrabold max-w-[720px] text-[#FFCB05] mt-[50px]">
               Sponsorships
             </div>
             <div className="">
               <div className="text-white md:text-2xl text-xl md:max-w-[40vw]">
                 We believe in making a difference in the communities we serve.
                 One of the ways we do this is through events that promote
                 sports, art, culture, wellness & education
               </div>
             </div>
           </div>
           
         </>
       }
       
     />
   </div>
   </>
  );
}

export default Section1;
