
import Playstore from './images/playstore2.png';
import Union from './images/Union.png';
import NorthAvenue from './images/NorthAvenue.png';
import Mica from './images/MICA.png';
import Iconic from './images/Iconic.png';
import FCR from './images/FCR.png';
import DP from './images/DP.png';
import Continental from './images/Continental.png';
import KIA from './images/Kia.png';
import Garodi from './images/Garodi.png';
import S from './images/S.png';
import Equalize from './images/Equalize.png';
import AMACS from './images/amacs.png';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
const animation = { duration: 20000, easing: (t) => t }
function Section4() {
  const [sliderRef] = useKeenSlider({
    loop:true,
    renderMode: "performance",
    created(s) {
      s.moveToIdx(5, true, animation)
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    breakpoints: {
      "(min-width: 300px)": {
        slides: { perView: 2, spacing: 5 },
      },
      "(min-width: 1000px)": {
        slides: { perView: 7, spacing: 20 },
      },
    },
   
  })
  return (
    
    <>

    <div className=''>
    
      <img className="w-full h-full object-cover" src={Playstore} alt="relax" />
    </div>
    <div className='bg-black md:px-[20px] pt-[20px] pb-[70px] w-[100vw] '>
      <h1 className='md:text-5xl text-[#666666]  py-[60px] text-4xl md:text-left text-center'>Our Notable Projects</h1>
      <div className=' keen-slider' ref={sliderRef}>
        
        <div className='border-[#666666] border p-[30px] h-[200px] w-[200px] items-center justify-center flex keen-slider__slide number-slide1'>
        <img src={Union} className='grayscale hover:grayscale-0'></img>
        </div>
        <div className='border-[#666666] border p-[30px] h-[200px] w-[200px]  items-center justify-center flex keen-slider__slide number-slide2'>
        <img src={NorthAvenue} className='grayscale hover:grayscale-0'></img>
        </div>
        <div className='border-[#666666] border p-[30px] h-[200px] w-[200px]  items-center justify-center flex keen-slider__slide number-slide3'>
        <img src={Mica} className='grayscale hover:grayscale-0'></img>
        </div>
        <div className='border-[#666666] border p-[30px] h-[200px] w-[200px] items-center justify-center flex keen-slider__slide number-slide4'>
        <img src={Iconic} className='grayscale hover:grayscale-0'></img>
        </div>
        <div className='border-[#666666] border p-[30px] h-[200px] w-[200px] items-center justify-center flex  keen-slider__slide number-slide5'>
        <img src={FCR} className='grayscale hover:grayscale-0'></img>
        </div>
        <div className='border-[#666666] border p-[30px] h-[200px] w-[200px]items-center justify-center flex keen-slider__slide number-slide6'>
        <img src={DP} className='grayscale hover:grayscale-0'></img>
        </div>
        <div className='border-[#666666] border p-[30px] h-[200px] w-[200px] items-center justify-center flex  keen-slider__slide number-slide7'>
        <img src={Continental} className='grayscale hover:grayscale-0'></img>
        </div>
        <div className='border-[#666666] border p-[30px] h-[200px] w-[200px] items-center justify-center flex  keen-slider__slide number-slide7'>
        <img src={KIA} className='grayscale hover:grayscale-0'></img>
        </div>
        <div className='border-[#666666] border p-[30px] h-[200px] w-[200px] items-center justify-center flex keen-slider__slide number-slide7'>
        <img src={Garodi} className='grayscale hover:grayscale-0'></img>
        </div>
        <div className='border-[#666666] border p-[30px] h-[200px] w-[200px] items-center justify-center flex keen-slider__slide number-slide7'>
        <img src={S} className='grayscale hover:grayscale-0'></img>
        </div>
        <div className='border-[#666666] border p-[30px] h-[200px] w-[200px] items-center justify-center flex keen-slider__slide number-slide7'>
        <img src={Equalize} className='grayscale hover:grayscale-0'></img>
        </div>
        <div className='border-[#666666] border p-[30px] h-[200px] w-[200px] items-center justify-center flex keen-slider__slide number-slide7'>
        <img src={AMACS} className='grayscale hover:grayscale-0'></img>
        </div>

      </div>

    </div>
    </>
  );
}

export default Section4;
