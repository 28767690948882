import Description from './Description';
function Section2() {
  return (
    <div className="py-8 min-h-screen relative">
      <div className="h-full">
        <div className="h-full flex justify-center items-center">
          <Description />
        </div>
      </div>
    </div>
  );
}

export default Section2;
