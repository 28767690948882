import { RouterProvider } from 'react-router-dom';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import router from './routes/Routes';

const App = () => {
  useEffect(() => {
    const tagManagerArgs = {
        gtmId: 'G-M32GTC3618'
    };
    TagManager.initialize(tagManagerArgs);
}, []);
  return <RouterProvider router={router} />;
};

export default App;
