import Commercial from './images/commercial2.png';
import Home from './images/home2.png';
import Timeline from './images/timeline.png';
import Interiors from './images/interiors.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

function Description() {
  useEffect(() => {
    AOS.init({duration: 3000});
  }, [])
  return (
    <>
      <div className="flex flex-col justify-between items-center pl-auto md:pl-16 bg-[url('modules/home/sections/section2/Group.png')] bg-cover bg-no-repeat lg:h-full py-[30px]" data-aos="fade-left">
        <div className="md:max-w-[40vw] py-[39px]">
          <div className="text-[#FFC906] text-2xl md:text-5xl mb-11 text-center uppercase font-medium">
            On Going Projects as on 2024
          </div>


        </div>
        <div className='flex flex-col gap-[30px] md:flex-row pt-[20px] pb-[50px] lg:gap-[150px]'>
          <div className='bg-[#FFC906]/70 w-[300px] h-[300px] rounded-md flex flex-col items-center justify-center gap-[20px]'>
            <img src={Home} className='w-[70px] h-[70px]'></img>
            <div className="text-[#000000] text-xl w-[150px] text-center uppercase font-medium">
              Residential Construction
            </div>
            <div className="text-[#000000] text-2xl  text-center uppercase font-medium">
              88+
            </div>
          </div>
          <div className='bg-[#FFC906]/70 w-[300px] h-[300px] rounded-md flex flex-col items-center justify-center gap-[20px]'>
            <img src={Commercial} className='w-[70px] h-[70px]'></img>
            <div className="text-[#000000] text-xl w-[150px] text-center uppercase font-medium">
              Commercial Construction
            </div>
            <div className="text-[#000000] text-2xl  text-center uppercase font-medium">
              15+
            </div>
          </div>
          <div className='bg-[#FFC906]/70 w-[300px] h-[300px] rounded-md flex flex-col items-center justify-center gap-[20px]'>
            <img src={Interiors} className='w-[70px] h-[70px]'></img>
            <div className="text-[#000000] text-xl w-[150px] text-center uppercase font-medium">
            Interiors
            </div>
            <div className="text-[#000000] text-2xl  text-center uppercase font-medium">
              25+
            </div>
          </div>

        </div>

      </div>
      <div className='md:mt-[100px] mt-[50px] lg:px-[100px]'>
      <div className="text-[#666666] text-2xl md:text-6xl mb-[50px] text-center font-medium  ">
            Our Journey has been marked by milestones of success
          </div>
        <img className=" w-full object-cover" src={Timeline} alt="relax" />
      </div>
    </>
  );
}

export default Description;
