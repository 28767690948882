function Section4() {
  return (
    <div className="flex">
      <div className="flex min-h-screen justify-between h-full w-full">
        <div className="flex min-h-screen justify-between h-full w-full flex-col p-6">
          <div className="md:text-3xl text-xl flex justify-center items-center w-full">
            <div className="text-[#996C0B] shrink-0 mr-4"> Our Process </div>
            <hr className="w-full border border-[#996C0B]"></hr>
          </div>
          <div className="mb-20">
            <div className="flex gap-2">
              <div className="md:w-[800px]">
                <div>
                  <div className="text-xs mb-6">01</div>
                  <div className="text-sm font-semibold">
                    Let&apos;s study the feasibility of your project.
                  </div>
                </div>
              </div>
              <div className="text-xs md:text-base">
                Following our first contact by email or telephone, we will first
                ask you, because we must not forget that it is your project. To
                offer you the most suitable answer, we will need elements such
                as your program, your needs and your budget. Following your
                return, there will be a first on-site or video meeting for an
                in-depth approach allowing us to establish the most coherent
                support offer for you. At this stage no drawing, but time to
                absorb the place and your needs.
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="flex h-full items-center justify-center">
            <div className="bg-[#F4EAD1] flex h-full items-center justify-center">
              <div className="md:text-xs text-[6px] font-semibold p-[2px] md:p-2 transform rotate-180 writing-mode-vertical-lr">
                Design of your living space
              </div>
            </div>
            <div className="bg-[#F1D285] flex h-full items-center justify-center">
              <div className="md:text-xs text-[6px] font-semibold p-[2px] md:p-2 transform rotate-180 writing-mode-vertical-lr">
                Technique & cost
              </div>
            </div>
            <div className="bg-[#D8B251] flex h-full items-center justify-center">
              <div className="md:text-xs text-[6px] font-semibold p-[2px] md:p-2 transform rotate-180 writing-mode-vertical-lr">
                Site monitoring
              </div>
            </div>
            <div className="bg-[#A6832A] flex h-full items-center justify-center">
              <div className="md:text-xs text-[6px] font-semibold p-[2px] md:p-2 transform rotate-180 writing-mode-vertical-lr ">
                Decoration & furniture
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section4;
