import Section from '../../uikit/section';
import Section1 from './sections/section1';
import Section2 from './sections/section2';
import Section3 from './sections/section3';
import Section7 from '../home/sections/section5';

const Sponsorships = () => {
  return (
    <div className="min-h-screen">
      <Section bgClass="bg-black">
        <Section1 />{' '}
      </Section>
      <Section bgClass="bg-black">
        <Section2 />{' '}
      </Section>
      <Section bgClass="bg-black">
        <Section3 />{' '}
      </Section>
      <Section bgClass="bg-[#F4EAD1] justify-center flex items-center min-h-full md:min-h-screen py-[70px] md:mt-0 " heightClass="h-full">
        <Section7 />{' '}
      </Section>
    </div>
  );
};

export default Sponsorships;
