import PropTypes from 'prop-types';

function Section({ bgClass, heightClass, children }) {
  return <div className={`w-full ${bgClass} ${heightClass}`}>{children}</div>;
}

Section.propTypes = {
  bgClass: PropTypes.string,
  heightClass: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Section.defaultProps = {
  bgClass: 'bg-gray-500',
  heightClass: 'min-h-screen',
};

export default Section;
