import Section from '../../uikit/section';
import ContinuousScroll from '../home/ContinuousScroll';


import Section1 from './sections/section1';
import Section2 from './sections/section2';
import Section3 from './sections/section3';
import Section4 from './sections/section4';
import Section5 from '../home/sections/section5';

const Homes = () => {
  return (
    <div className="min-h-screen">
      <Section bgClass="bg-[#ffff]">
        <Section1 />{' '}
      </Section>
      <Section heightClass="min-h-full">
        <Section2 />{' '}
      </Section>
      
      {
      //   <Section bgClass="bg-white">
      //   <Process />
      // </Section>
      }
      {
      //   <Section bgClass="bg-[#141314]">
      //   <Section3 />{' '}
      // </Section>
      }
      <Section bgClass="bg-[url('modules/homes/sections/section2/images/Background.jpg')]">
        <Section4 />{' '}
      </Section>
      <Section bgClass="bg-[#F4EAD1] justify-center flex items-center  md:min-h-screen min-h-full py-[70px]">
        <Section5 />{' '}
      </Section>
      <div className="bg-[#F4EAD1]  text-[#9F683B] py-5 border-t border-[#9F683B] mt-[-50px] max-w-full w-full ">
        <ContinuousScroll text=" FIND OUT WHAT CAN WE DO FOR YOU / &nbsp;" />
      </div>
    </div>
  );
};

export default Homes;
