import Card from './Card';
import Work4 from './images/work4.webp';
import Work3 from './images/work3.webp';
import Work5 from './images/work5.webp';
import Work6 from './images/work6.webp';
import Work7 from './images/kia.webp';
import Work8 from './images/nakhsha.webp';
import img1 from './images/folder2/Asset 40.jpg'
import img2 from './images/folder2/Asset 60.jpg'
import img3 from './images/folder2/Asset 50.jpg'
import img4 from './images/folder2/Asset 70.jpg'
import img5 from './images/folder2/Asset 80.jpg'
import img6 from './images/folder2/Asset 90.jpg'
import img7 from './images/folder2/Asset 100.jpg'
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"

const carousel = (slider) => {
  const z = 350
  function rotate() {
    const deg = 360 * slider.track.details.progress
    slider.container.style.transform = `translateZ(${z}px) rotateY(${-deg}deg)`
  }
  slider.on("created", () => {
    const deg = 360 / slider.slides.length
    slider.slides.forEach((element, idx) => {
      element.style.transform = `rotateY(${deg * idx}deg) translateZ(${z}px)`
    })
    rotate()
  })
  slider.on("detailsChanged", rotate)
}



function Works() {
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
      selector: ".carousel__cell",
      renderMode: "custom",
      mode: "free-snap",
    },
    [carousel]
  )
  return (
    <div className="overflow-hidden">
      <hr className="border border-[#B5B5B5] my-12"></hr>

      {/* Our Works Section */}
      <div className="flex justify-around items-center flex-col">
        <div className="text-3xl md:text-5xl leading-none  font-bold  text-center">
          <div>Our Works</div>
          </div>
          <div className="wrapper w-full">
      <div className="scene  w-full" >
        <div className="carousel keen-slider" ref={sliderRef}>
          <div className="carousel__cell number-slide1 "><a href={img1}><img src={img1} className='w-full h-full'></img></a></div>
          <div className="carousel__cell number-slide2"><a href={img2}><img src={img2} className='w-full h-full'></img></a></div>
          <div className="carousel__cell number-slide3"><a href={img3}><img src={img3} className='w-full h-full'></img></a></div>
          <div className="carousel__cell number-slide4"><a href={img4}><img src={img4} className='w-full h-full'></img></a></div>
          <div className="carousel__cell number-slide5"><a href={img5}><img src={img5}className='w-full h-full'></img></a></div>
          <div className="carousel__cell number-slide6"><a href={img6}><img src={img6}className='w-full h-full' ></img></a></div>
          <div className="carousel__cell number-slide6"><a href={img7}><img src={img7}className='w-full h-full' ></img></a></div>
        </div>
      </div>
    </div>
    
        
        <div className='mt-[150px] md:px-[100px] text-xl font-semibold'>
        <p className='text-center'>Discover our comprehensive expertise in architecture, interiors, and product design, tailored to bring your vision to life. 
With a keen eye for detail and innovation, we craft spaces and products that redefine excellence. 
Let us transform your ideas into reality with our unparalleled craftsmanship and dedication to quality.</p>
</div>
      </div>
     
    </div>
  );
}

export default Works;
