import ImageGallery from '../../../../uikit/imageGallery';
import NakhshaAward from './images/award2.jpg';
import AwardTable from './AwardTable';
import P2 from './images/P2.png';
import A1 from './images/A1.jpg';
import A2 from './images/A2.jpg';
import A3 from './images/A3.jpg';
import A4 from './images/A4.jpg';
import A5 from './images/A5.jpg';
import A6 from './images/A6.jpg';
import A7 from './images/A7.jpg';
import A8 from './images/A8.png';
import A9 from './images/A9.png';
import A10 from './images/A10.png';
import A11 from './images/A11.jpg';
import A12 from './images/A12.jpg';
import A13 from './images/A13.jpg';
import A14 from './images/A14.jpg';
import A15 from './images/A15.jpg';
import A16 from './images/A16.jpg';
import A17 from './images/A17.jpg';
import A18 from './images/A18.jpg';
import A19 from './images/A19.jpg';
import A20 from './images/A20.jpg';

import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
const animation = { duration: 20000, easing: (t) => t }

 

  
function Description() {
  const [sliderRef] = useKeenSlider({
    loop:true,
    renderMode: "performance",
    created(s) {
      s.moveToIdx(5, true, animation)
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    breakpoints: {
      "(min-width: 300px)": {
        slides: { perView: 2, spacing: 20 },
      },
      "(min-width: 1000px)": {
        slides: { perView: 3, spacing: 100 },
      },
    },
   
  })

  return (
    <div className="">
      <div className="flex md:flex-row flex-col items-center justify-center p-[20px] pt-0">
       
        <div className="bg-opacity-80 text-left md:p-[50px]  md:pb-20 pb-2 md:text-4xl text-2xl text-[#3b3b3b] font-extrabold md:w-[60%] w-full" >
          Honors and Accolades: <br></br><span className='text-black font-medium text-2xl'>Celebrating the Recognition of Our Construction Company</span>
          <div className="bg-opacity-80 text-left md:text-2xl text-xl text-black font-medium mt-[10px]" >
          we proudly showcase the accolades and honors bestowed upon our
          construction company. Through a diverse array of industry awards,
          certifications, and acknowledgments, we invite you to explore the
          exceptional achievements that distinguish us as a leader in the
          construction sector.
        </div>
          
        </div>
        <div className='md:w-[40%] w-full'>
            <img src={P2}></img>
        </div>
        
        
      </div>
      <div className="flex flex-row md:p-[50px] py-[0px] pb-[50px] pt-[20px] gap-1 max-sm:hidden justify-center">
       <div className='flex flex-col gap-1 '>
        <div className='flex flex-row gap-1 w-[50%] h-[50%]'>
        <img src={A1} ></img>
        <img src={A2}></img>
        </div>
          <div className='flex flex-row gap-1 w-[50%] h-[50%]'>
        <img src={A3}></img>
        <img src={A4}></img>
          </div>
                  </div>
          <img src={A5} className=''></img>
    
       
          <div className='flex flex-col gap-1 '>
        <img src={A6} className='h-[50%]'></img>
        <img src={A7} className='h-[50%]'></img>
          </div>
          
       
      </div>
      <div className='bg-black '>
        <div className="bg-[url('modules/pressMedia/sections/section2/images/Awards100.png')] w-full h-[200px] bg-no-repeat bg-center flex items-center justify-center"> 
            <h3 className='text-[#B39962] absolute text-5xl font-bold'>Awards</h3>
        </div>
        <div className='w-full flex lg:flex-row flex-col gap-[40px] items-center justify-evenly pb-[50px] md:px-[150px] px-[40px]'>
        <img src={A8}></img>
        <img src={A9}></img>
        <img src={A10}></img>
        </div>

      </div>
    <div className='w-[100vw]'>
    <div className="bg-[url('modules/pressMedia/sections/section2/images/Media.png')] w-full md:h-[200px] h-[100px] bg-no-repeat bg-center flex items-center justify-center max-sm:my-[20px]"> 
            <h3 className='text-black absolute text-5xl font-bold'>Media</h3>
        </div>
      <div className='px-[0px] py-[50px] keen-slider' ref={sliderRef}>
      <img
              className="rounded  w-full md:h-[700px] h-[250px] shadow-2xl flex keen-slider__slide number-slide1"
              src={A11}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[700px] h-[250px] shadow-2xl flex keen-slider__slide number-slide1"
              src={A12}
              alt="relax"

              
            />
            <img
              className="rounded  w-full md:h-[700px] h-[250px] shadow-2xl flex keen-slider__slide number-slide1"
              src={A13}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[700px] h-[250px] shadow-2xl flex keen-slider__slide number-slide1"
              src={A14}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[700px] h-[250px] shadow-2xl flex keen-slider__slide number-slide1"
              src={A15}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[700px] h-[250px] shadow-2xl flex keen-slider__slide number-slide1"
              src={A16}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[700px] h-[250px] shadow-2xl flex keen-slider__slide number-slide1"
              src={A17}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[700px] h-[250px] shadow-2xl flex keen-slider__slide number-slide1"
              src={A18}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[700px] h-[250px] shadow-2xl flex keen-slider__slide number-slide1"
              src={A19}
              alt="relax"

            />
            <img
              className="rounded  w-full md:h-[700px] h-[250px] shadow-2xl flex keen-slider__slide number-slide1"
              src={A20}             alt="relax"

            />
        </div>
</div>

    </div>
  );
}

export default Description;
