import Vinay from './images/Vinay.jpg';
import Pratibha from './images/pratibha.webp';
import Sowrabha from './images/Sowrabha.jpg';
import RaviKumar from './images/Ravi2.jpg';
import Adithya from './images/aditya.jpg';
import Forces from './images/Group.jpg';
import Section5 from '../home/sections/section5';
import Section from '../../uikit/section';
import vinay from './images/Vi MV.psd100.png';
import prathiba from './images/PN MV.psd100.png';
import ravi from './images/Ra MV.psd100.png';
import sowrabha from './images/So MV.psd100.png';
import aditya from './images/ad MV.psd100.png';
function Leadership() {
  return (
    <>
      <div className="min-h-screen bg-[#141314] pt-16 py-20">
        <div className=' w-full p-4 px-0  m-auto'>
          <div className="md:p-20 p-4 text-[#C6AB6E]  text-3xl md:text-5xl  lg:text-5xl text-center" style={{ fontFamily: 'IvyMode' }}>
            The Guiding Forces Behind NAKHSHA
          </div>
          <img
            src={Forces}
            alt="Forces"
            className="mb-20 w-full "

          />

          <div className='flex flex-col gap-20 max-md:hidden'>
            <div className="flex justify-center bg-[url('modules/leadership/images/Vi.png')] bg-right bg-cover bg-no-repeat md:h-[750px]" >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-0 content-center  text-[#FBFBFB] w-full">

                <div className="md:text-[#FBFBFB] text-[#C99D2B] text-xl text-left md:px-[30px] ">
                  {' '}
                  <h1 className='text-[#C99D2B] text-4xl font-extrabold'>Vinay Shankar</h1>
                  <div className="text-xl font-extrabold mb-[20px] mt-[0px]">CEO</div>
                  <div className="text-center md:text-left">
                    {' '}
                    Vinay Shankar, MBA graduate from RV Institute of Management
                    and executive education at IIM-Bangalore, is an innovative Managing Director known for founding Cheers Mysore in 2013, predating Swiggy. His journey in Real Estate began with a leading Mysore
                    construction company, reaching CEO status at 24. Co-founding
                    Nakhsha Group in 2015, Vinay positioned it as a leader in quality
                    construction, with NAKHSHA now Mysores premier private construction contractor. Also, as the Managing Partner at Mysore
                    Union, he oversees Mysores largest private Sports & Fitness Club,
                    recipient of the Times Business Award 2020 for excellence.
                  </div>
                </div>


              </div>
            </div>
            <div className="flex justify-center bg-[url('modules/leadership/images/PN.png')] bg-cover bg-no-repeat md:h-[750px]" >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-0 content-center  text-[#FBFBFB] w-full">
                <div>

                </div>

                <div className="md:text-[#FBFBFB] text-[#C99D2B] text-xl text-right md:px-[30px] ">
                  {' '}
                  <h1 className='text-[#C99D2B] text-4xl font-extrabold'>Pratibha Nayak</h1>
                  <div className="text-xl font-extrabold mb-[20px] mt-[0px]">DIRECTOR</div>
                  <div className="text-center md:text-right">
                    {' '}
                    Pratibha Nayak, a self-made entrepreneur and civil engineer, stands out as a
                    leading female entrepreneur in Mysurus real estate sector. As a member of
                    Builders Association of India and NAREDCO, she co-founded Nakhsha Group,
                    overseeing brands like Nakhsha Homes, Nakhsha Collectives, and Nakhsha
                    Furnishing Studio. Pratibha, a partner in various ventures, including Mysore Union
                    and Union Catering, Toni & Guy, is recognized for her commitment to transparency,
                    trust, affordability, and quality. With almost 100 projects completed, she leads a
                    team of 100 professionals, showcasing innovation in construction, hospitality,
                    decor, and events. Her influence extends beyond her core business into diverse
                    ventures, reflecting her dedication to success and professionalism
                  </div>
                </div>


              </div>
            </div>
            <div className="flex justify-center bg-[url('modules/leadership/images/ra.png')] bg-right bg-cover bg-no-repeat md:h-[750px]" >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-0 content-center  text-[#FBFBFB] w-full">


                <div className="md:text-[#FBFBFB] text-[#C99D2B] text-xl text-left md:px-[30px] ">
                  {' '}
                  <h1 className='text-[#C99D2B] text-4xl font-extrabold'>Ravi Kumar</h1>
                  <div className="text-xl font-extrabold mb-[20px] mt-[0px]">CHIEF OPERATING OFFICER</div>
                  <div className="text-center md:text-left">
                    {' '}
                    Ravi Kumar, a Civil Engineering and Structural Engineering graduate, with 10+
                    years of experience, has successfully completed 40+ projects. As a former Chief
                    Engineer at Nakhsha, his expertise includes Structural Designing, Project Planning,
                    team building, and interior execution
                  </div>
                </div>


              </div>
            </div>
            <div className="flex justify-center bg-[url('modules/leadership/images/so.png')] bg-cover bg-no-repeat md:h-[750px]" >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-0 content-center  text-[#FBFBFB] w-full">

                <div>

                </div>
                <div className="md:text-[#FBFBFB] text-[#C99D2B] text-xl text-right md:px-[30px] ">
                  {' '}
                  <h1 className='text-[#C99D2B] text-4xl font-extrabold'>Sowrabha Prakash</h1>
                  <div className="text-xl font-extrabold mb-[20px] mt-[0px]">CHIEF DESIGN OFFICER</div>
                  <div className="text-center md:text-right">
                    {' '}
                    Sowrabha Prakash, a meticulous architecture designer, leads her team
                    to excel in their specialties. Her portfolio includes notable projects like
                    MICA, Cosmopolitan Club-guest rooms, Vithmas, River View Resort,
                    Nature Resort, and Nakhsha Square, showcasing her commitment
                    to seeing concepts through to completion.
                  </div>
                </div>


              </div>
            </div>
            <div className="flex justify-center bg-[url('modules/leadership/images/ad.png')] bg-right bg-cover bg-no-repeat md:h-[750px]" >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-0 content-center  text-[#FBFBFB] w-full">


                <div className="md:text-[#FBFBFB] text-[#C99D2B] text-xl text-left md:px-[30px] ">
                  {' '}
                  <h1 className='text-[#C99D2B] text-4xl font-extrabold'>Adithya A A</h1>
                  <div className="text-xl font-extrabold mb-[20px] mt-[0px]">CHIEF MARKETING OFFICER</div>
                  <div className="text-center md:text-left">
                    {' '}
                    Adithya A A, a Dynamic Chief Marketing Officer &
                    A Successful Marketing leader with 7+ years experience,
                    skilled in mission-critical results, cross-cultural team management,
                    and strategic alliances. Holds executive education from IIMB, MBA
                    in CSR, and a Post-Graduation in HRM
                  </div>
                </div>


              </div>
            </div>




          </div>
          <div className='mobileview'>
            <div className='flex flex-col'>

              <img src={vinay} className='w-full '></img>
              <div className='absolute flex flex-col items-center justify-end h-[77vw] w-full'>
                <h1 className='text-[#C99D2B] text-4xl font-extrabold '>Vinay Shankar</h1>
                <div className="text-xl font-extrabold mb-[20px] mt-[0px] text-white">CEO</div>
              </div>
              <div className=" text-white p-4">
                {' '}
                Vinay Shankar, MBA graduate from RV Institute of Management
                and executive education at IIM-Bangalore, is an innovative Managing Director known for founding Cheers Mysore in 2013, predating Swiggy. His journey in Real Estate began with a leading Mysore
                construction company, reaching CEO status at 24. Co-founding
                Nakhsha Group in 2015, Vinay positioned it as a leader in quality
                construction, with NAKHSHA now Mysores premier private construction contractor. Also, as the Managing Partner at Mysore
                Union, he oversees Mysores largest private Sports & Fitness Club,
                recipient of the Times Business Award 2020 for excellence.
              </div>

            </div>
            <div className='flex flex-col'>

              <img src={prathiba} className='w-full '></img>
              <div className='absolute flex flex-col items-center justify-end h-[77vw] w-full'>
                <h1 className='text-[#C99D2B] text-4xl font-extrabold '>Pratibha Nayak</h1>
                <div className="text-xl font-extrabold mb-[20px] mt-[0px] text-white">Director</div>
              </div>
              <div className=" text-white p-4 text-right">
                {' '}
                Pratibha Nayak, a self-made entrepreneur and civil engineer, stands out as a
                leading female entrepreneur in Mysurus real estate sector. As a member of
                Builders Association of India and NAREDCO, she co-founded Nakhsha
                Group, overseeing brands like Nakhsha Homes, Nakhsha Collectives, and
                Nakhsha Furnishing Studio. Pratibha, a partner in various ventures, including
                Mysore Union and Union Catering, Toni & Guy, is recognized for her
                commitment to transparency, trust, affordability, and quality. With almost
                100 projects completed, she leads a team of 100 professionals, showcasing
                innovation in construction, hospitality, decor, and events. Her influence
                extends beyond her core business into diverse ventures, reflecting her
                dedication to success and professionalism.
              </div>

            </div>
            <div className='flex flex-col'>

              <img src={ravi} className='w-full '></img>
              <div className='absolute flex flex-col items-center justify-end h-[77vw] w-full'>
                <h1 className='text-[#C99D2B] text-4xl font-extrabold '>Ravi Kumar</h1>
                <div className="text-xl font-extrabold mb-[20px] mt-[0px] text-white">CHIEF OPERATING OFFICER</div>
              </div>
              <div className=" text-white p-4 ">
                {' '}
                Ravi Kumar, a Civil Engineering and Structural Engineering graduate, with
                10+ years of experience, has successfully completed 40+ projects. As a
                former Chief Engineer at Nakhsha, his expertise includes Structural
                Designing, Project Planning, team building, and interior execution
              </div>

            </div>
            <div className='flex flex-col'>

              <img src={sowrabha} className='w-full '></img>
              <div className='absolute flex flex-col items-center justify-end h-[77vw] w-full'>
                <h1 className='text-[#C99D2B] text-4xl font-extrabold '>Sowrabha Prakash</h1>
                <div className="text-xl font-extrabold mb-[20px] mt-[0px] text-white">CHIEF DESIGN OFFICER</div>
              </div>
              <div className=" text-white p-4 text-right">
                {' '}
                Sowrabha Prakash, a meticulous architecture designer, leads her team
                to excel in their specialties. Her portfolio includes notable projects like
                MICA, Cosmopolitan Club-guest rooms, Vithmas, River View Resort,
                Nature Resort, and Nakhsha Square, showcasing her commitment
                to seeing concepts through to completion.
              </div>

            </div>
            <div className='flex flex-col'>

              <img src={aditya} className='w-full '></img>
              <div className='absolute flex flex-col items-center justify-end h-[77vw] w-full'>
                <h1 className='text-[#C99D2B] text-4xl font-extrabold '>Adithya A A</h1>
                <div className="text-xl font-extrabold mb-[20px] mt-[0px] text-white">CHIEF MARKETING OFFICER</div>
              </div>
              <div className=" text-white p-4 ">
                {' '}
                Adithya A A, a Dynamic Chief Marketing Officer &
                A Successful Marketing leader with 7+ years experience,
                skilled in mission-critical results, cross-cultural team management,
                and strategic alliances. Holds executive education from IIMB, MBA
                in CSR, and a Post-Graduation in HRM
              </div>

            </div>

          </div>
        </div>
      </div>
      <Section bgClass="bg-[#F4EAD1] justify-center flex items-center min-h-full md:min-h-screen py-[70px] md:mt-0 " heightClass="h-full">
        <Section5 />{' '}
      </Section>
    </>
  );
}

export default Leadership;
