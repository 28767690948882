import About1 from './images/AboutN1.webp';
import About2 from './images/AboutN2.webp';
import Dak from './images/Dak.png';
import Fis from './images/Fis.png';
import For from './images/for.png';
import Peb from './images/Peb.png';
import Uni from './images/uni.png';
import Mica from './images/mica.png';
import Nor from './images/Nor.png';
import Grs from './images/Grs.png';
import J from './images/J .png';
import Ico from './images/ico.png';
import Continental from './images/continental.jpg';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
const animation = { duration: 20000, easing: (t) => t }


function Description() {
  const [sliderRef] = useKeenSlider({
    loop:true,
    renderMode: "performance",
    created(s) {
      s.moveToIdx(5, true, animation)
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    breakpoints: {
      "(min-width: 300px)": {
        slides: { perView: 2, spacing: 15 },
      },
      "(min-width: 1000px)": {
        slides: { perView: 3, spacing: 20 },
      },
    },
   
  });
  return (
    <>  
    <div className="mt-3 ">
      <div className="md:text-5xl text-3xl font-bold text-left px-10 pt-5 pb-0" style={{fontFamily:'Gotham'}}>About us</div>
      <div className="my-[0px]">
        
        <div>
          <div className="flex gap-4 justify-start text-left px-10">
            <div className="flex flex-col w-full   gap-2  ">
              
              <div className="text-lg md:text-xl" >
                Where innovation meets functionality and businesses thrive in
                inspiring environments.<br></br> we&apos;re here to bring your commercial
                vision to life.
              </div>
              <div className="text-xl md:text-lg">
                We empower businesses to achieve their goals and leave a lasting
                impression on clients and customers alike.
              </div>
            </div>
           
            
          </div>
          
         
        </div>
      </div>
      
      
    </div>
    <div className='bg-black md:px-[20px] pt-[20px] mt-[100px] md:mt-[200px] h-[200px] md:h-[350px] w-[100vw] absolute'>
  
      

  </div>
  <div className='w-[100vw]'>
  <div className='h-[300px] md:h-[500px] keen-slider w-full ' ref={sliderRef}>
      
      <div className='   h-full w-full items-center justify-center flex keen-slider__slide number-slide1  rounded-md  drop-shadow-xl'>
      <img src={Dak} className=' '></img>
      </div>
      <div className='   h-full w-full  items-center justify-center flex keen-slider__slide number-slide2 rounded-md drop-shadow-xl'>
      <img src={Fis} className=' '></img>
      </div>
      <div className='   h-full w-full  items-center justify-center flex keen-slider__slide number-slide3 rounded-md drop-shadow-xl'>
      <img src={For} className='s'></img>
      </div>
      <div className='   h-full w-full  items-center justify-center flex keen-slider__slide number-slide3 rounded-md drop-shadow-xl'>
      <img src={Peb} className=' '></img>
      </div><div className='   h-full w-full  items-center justify-center flex keen-slider__slide number-slide3 rounded-md drop-shadow-xl'>
      <img src={Uni} className=' '></img>
      </div>
      <div className='   h-full w-full  items-center justify-center flex keen-slider__slide number-slide3 rounded-md drop-shadow-xl'>
      <img src={Mica} className=''></img>
      </div>
      <div className='   h-full w-full  items-center justify-center flex keen-slider__slide number-slide3 rounded-md drop-shadow-xl'>
      <img src={Nor} className=' '></img>
      </div>
      <div className='   h-full w-full  items-center justify-center flex keen-slider__slide number-slide3 rounded-md drop-shadow-xl'>
      <img src={Grs} className=''></img>
      </div>
      <div className='   h-full w-full  items-center justify-center flex keen-slider__slide number-slide3 rounded-md drop-shadow-xl'>
      <img src={J} className=''></img>
      </div>
      <div className='   h-full w-full  items-center justify-center flex keen-slider__slide number-slide3 rounded-md drop-shadow-xl'>
      <img src={Ico} className=' '></img>
      </div>
      <div className='   h-full w-full  items-center justify-center flex keen-slider__slide number-slide3 rounded-md drop-shadow-xl'>
      <img src={Continental} className=' rounded-xl'></img>
      </div>

    </div>
    </div>
    
   
  </>
  );
}

export default Description;
