import Carousel from '../../../../uikit/simple-carousel';
import p1 from './images/P1.png';
import p2 from './images/P2.png';
import p3 from './images/P3.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';


function Section1() {
  useEffect(() => {
    AOS.init({duration: 3000});
  }, [])
  return (
    <>
    <div className="h-screen max-md:hidden">
      <Carousel
        images={[
          {
            
            src: './images/collectives.jpg',
            alt: 'Image 1',
          },
        ]}
        element={
          <>
            <div className="absolute w-full h-full md:flex justify-center items-end flex-col hidden md:visible " >
              <div className="z-10  flex justify-end items-end  md:flex-row  gap-[10px] " data-aos="fade-left" >
                
                  <img src={p1} className='md:w-[250px] md:h-[400px] w-[200px] h-[200px]' alt=''></img>
                  <img src={p2} className='md:w-[250px] md:h-[400px] w-[200px] h-[200px]' alt=''></img>
                  <img src={p3} className='md:w-[250px] md:h-[400px] w-[200px] h-[200px]' alt=''></img>
                
              
              </div>
            </div>
            <div className="absolute z-[2] top-0 left-0 w-full h-full bg-black opacity-25"></div>
          </>
        }
        disableBackArrow
      />
    </div>
     <div className="h-screen mobileview">
     <Carousel
       images={[
         {
           
           src: './images/in100.png',
           alt: 'Image 1',
         },
       ]}
       element={
         <>
           
         </>
       }
       disableBackArrow
     />
   </div>
   </>
  );
}

export default Section1;
