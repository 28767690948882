import Carousel from '../../../../uikit/simple-carousel';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';


function Section1() {
  useEffect(() => {
    AOS.init({duration: 3000});
  }, [])
  return (
    <>
    <div className="h-screen max-md:hidden">
      <Carousel
        images={[
          {
            
            src: './images/Rectangle.png',
            alt: 'Image 1',
          },
          
        ]}
        element={
          <>
            <div className="absolute md:pl-[90px] bottom-[50%] pl-[0px] z-10 flex items-center justify-center flex-col w-full " data-aos="fade-left">
              <div className="lg:text-7xl md:text-7xl md:text-left text-3xl  w-full text-white leading-none font-bold text-center ">
               WE BUILD YOUR DREAM
              </div>
              
                
                <div className="text-white text-xl w-full text-center lg:text-left mt-[10px]">
                Mysuru’s Most Trusted & Preferred <br></br>Construction Company
               
              </div>
            </div>
            <div className="absolute z-[2]  w-full h-full bg-black opacity-25"></div>
          </>
        }
        
      />
    </div>
     <div className="h-screen mobileview">
     <Carousel
       images={[
         {
           
           src: './images/homemobilebanner.jpg',
           alt: 'Image 1',
         },
         
       ]}
       element={
         <>
           <div className="absolute md:pl-[90px] bottom-[50%] pl-[0px] z-10 flex items-center justify-center flex-col w-full " data-aos="fade-left">
             <div className="lg:text-7xl md:text-7xl md:text-left text-3xl  w-full text-white leading-none font-bold text-center ">
              WE BUILD YOUR DREAM
             </div>
             
               
               <div className="text-white text-xl w-full text-center lg:text-left mt-[10px]">
               Mysuru’s Most Trusted & Preferred <br></br>Construction Company
              
             </div>
           </div>
           <div className="absolute z-[2]  w-full h-full bg-black opacity-25"></div>
         </>
       }
       
     />
   </div>
   </>
  );
}


export default Section1;
