import Description from './Description';

function Section2() {
  return (
    <div className="md:pt-[100px] pb-[0px] ">
      <div className="">
        <div className=" flex justify-center items-center">
          <Description />
        </div>
      </div>
    </div>
  );
}

export default Section2;
