import Accordion from '../../../../uikit/accordion';
import A1 from './images/Asset1.png';
import A2 from './images/Asset2.png';
import A3 from './images/Asset3.png';
import A4 from './images/Asset4.png';
import Employee from './images/employee.webp';
import './Card.css';

function Description() {
  // TODO: Content for 2nd and 3rd Items dependency on vinay
  const items = [
    {
      title: 'Corporate Structure and Employee Benefits',
      content: (
        <p>
          At NAKHSHA, we’ve meticulously crafted a corporate structure that
          values and prioritizes our greatest asset – our employees. From sick
          leaves and casual leaves to festival holidays, we ensure that our team
          members have the support and flexibility they need to thrive both
          personally and professionally.
        </p>
      ),
    },
    {
      title: 'Investment in Training & Development',
      content: (
        <p>
          We believe in empowering our employees to reach their full potential.
          That&apos;s why we invest extensively in training and development
          programs to equip our team members with the skills, knowledge, and
          tools they need to excel in their roles and beyond.
        </p>
      ),
    },
    {
      title: 'Culture of Celebration',
      content: (
        <p>
          Monthly birthday celebrations, rewards & recognitions, sports
          tournaments, Christmas celebrations, Women&apos;s Day festivities –
          these are just a few ways we cultivate a culture of camaraderie, joy,
          and appreciation at NAKHSHA, we believe in recognizing and celebrating
          the contributions of every individual who contributes to our
          collective success.
        </p>
      ),
    },
    {
      title: 'Annual Day',
      content: (
        <p>
          Our Annual Day is a highlight of the year, where we come together to
          honour and celebrate the hard work, achievements, and milestones of
          our employees. It&apos;s a time to reflect on our journey, recognize
          outstanding performance, and inspire each other to reach new heights
          of excellence.{' '}
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="">
        <div className="">
          <div>
            <div className="">
              <div className="bg-[#f1f2f2]">


                <div className="md:text-xl text-md text-[#1E1D1C] font-bold md:px-[50px] px-[20px] py-[30px]">
                  <div className="mt-4 mb-4 md:text-justify text-left">
                    As one of the fastest-growing construction companies in
                    Mysore, we&apos;re always on the lookout for passionate,
                    talented individuals to join our team. Whether you&apos;re a
                    seasoned professional eager to make your mark, there&apos;s a
                    place for you at NAKHSHA. Come be a part of something special.
                    Come join NAKHSHA and embark on a journey of growth,
                    fulfilment, and endless possibilities. <br></br>Explore our current job
                    openings and take the first step towards a rewarding career
                    with us. We can&apos;t wait to welcome you to the NAKHSHA
                    family!
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="mt-12 md:mt-24 ">
          <div className="text-[#181817] text-center text-2xl  md:text-5xl font-bold md:text-center  px-[20px]" style={{ fontFamily: 'IvyMode' }}>
            Our Commitment to Our Team
          </div>
          <div className='flex items-center justify-center'>
            <div className="container mt-[0px]" style={{marginTop:"0px"}}>

              <div className="card bg-black">
                <div className='overlay'>
                  <div className="content">

                    <div className="contentBx">
                      <img src={A1} style={{ width: '150px', height: '150px' }}></img>
                      <h3 className='font-bold'>Corporate Structure and Employee Benefits</h3>
                    </div>
                  </div>

                  <p className="sci">

                    At NAKHSHA, we’ve meticulously crafted a corporate structure that
                    values and prioritizes our greatest asset – our employees. From sick
                    leaves and casual leaves to festival holidays, we ensure that our team
                    members have the support and flexibility they need to thrive both
                    personally and professionally.


                  </p>
                </div>
              </div>

              <div className="card bg-slate-400">
                <div className='overlay'>
                  <div className="content">

                    <div className="contentBx">
                      <img src={A2} style={{ width: '150px', height: '150px' }}></img>
                      <h3 className='font-bold'>Investment in Training &
                        Development</h3>
                    </div>
                  </div>

                  <p className="sci">

                    We believe in empowering our employees
                    to reach their full potential. Thats why
                    we invest extensively in training and
                    development programs to equip
                    our team members with the skills,
                    knowledge, and tools they need to excel
                    in their roles and beyond


                  </p>
                </div>
              </div>
              <div className="card bg-black">
                <div className='overlay'>
                  <div className="content">

                    <div className="contentBx">
                      <img src={A3} style={{ width: '150px', height: '150px' }}></img>
                      <h3 className='font-bold'>Culture of Celebration</h3>
                    </div>
                  </div>

                  <p className="sci">

                    Monthly birthday celebrations,
                    rewards & recognitions,
                    sports tournaments, Christmas
                    celebrations, Womens Day festivities –
                    these are just a few ways we cultivate
                    a culture of camaraderie, joy,
                    and appreciation at NAKHSHA.
                    


                  </p>
                </div>
              </div>
              <div className="card bg-slate-400">
                <div className='overlay'>
                  <div className="content items-start">

                    <div className="contentBx">
                      <img src={A4} style={{ width: '150px', height: '150px' }}></img>
                      <h3 className='font-bold text-left'>Annual Day</h3>
                    </div>
                  </div>

                  <p className="sci">

                    Our Annual Day is a highlight of the year,
                    where we come together to honour and
                    celebrate the hard work, achievements,
                    and milestones of our employees.
                    Its a time to reflect on our journey,
                    recognize outstanding performance,
                    and inspire each other to reach
                    new heights of excellence


                  </p>
                </div>
              </div>
            </div>
            
                </div>
              </div>
            </div>
         
       
     

    </>
  );
}

export default Description;
